import React, { useEffect, useState } from 'react'
import * as collectionService from '../../../../services/data-collection.service';
import SelectCollections from '../../../../components/data-collections/merge-collection/select-collections';
import { useParams } from 'react-router-dom';


const SelectCollectionController = (props) => {

    const { prevStepRef, setState, setMergeCollectionState, state, mergeCollectionState } = props;


    const [dataCollections, setDataCollections] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const { collectionUId } = useParams();

    useEffect(() => {
        getUserDataCollection()
    }, [])

    useEffect(() => {
        if (!state.collectionUIdsToMerge?.length || !collectionUId) return;
      
        setMergeCollectionState(prev => {
          const existingKeys = new Set(prev.selectedObjectCollections.map(item => item.key));
          
          const newCollections = state.collectionUIdsToMerge
            .map(uId => dataCollections.find(c => c.uId === uId))
            .filter(Boolean)
            .filter(c => !existingKeys.has(c.uId))
            .map(c => ({ key: c.uId, label: c.collectionName }));
      
          return {
            ...prev,
            selectedObjectCollections: [...prev.selectedObjectCollections, ...newCollections]
          };
        });
      }, [dataCollections, state.collectionUIdsToMerge, collectionUId]);
    


    const getUserDataCollection = () => {

        setIsDataLoading(true);

        collectionService.getAllCollections()
            .then((response) => {
                if (response?.hasError) {
                    setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });
                }
                else if (response?.data) {
                    setDataCollections(response?.data)
                }
            }).finally(() => {
                setIsDataLoading(false);
            })
    }


    const onChangeObjectSelectHandler = (selectedCollections) => {
        let collectionUIds = selectedCollections.map(c => c.key);

        setState((prevState) => { return { ...prevState, collectionUIdsToMerge: collectionUIds, } });
        setMergeCollectionState((prevState) => { return { ...prevState, selectedObjectCollections: selectedCollections, } });
    }

    return (
        <SelectCollections
            prevStepRef={prevStepRef}
            dataCollections={dataCollections}
            onChangeObjectSelectHandler={onChangeObjectSelectHandler}
            selectedObjectCollections={mergeCollectionState.selectedObjectCollections}
            isDataLoading={isDataLoading}
        />
    )
}

export default SelectCollectionController;