import React, { useEffect, useMemo, useRef, useState } from 'react'
import ColumnSelectionPopup from '../../../components/data-collections/column-selection-popup/column-selection-popup'
import BasicInformationController from '../add-new-data-collection/basic-information/basic-information-controller';
import * as collectionService from '../../../services/data-collection.service';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { testData } from '../../../services/test-data.service';
import { getDateRange } from './date-filter-controller/date-filter-helper';
import { getEquivalentPriorityDataType } from '../add-new-data-collection/select-columns/datatypes-equivalent-map';
import { toast } from 'react-toastify';
import { formatDisplayName } from '../utils/template-collection-parameter';
import { AppEnum } from '../../../constants/app-enum';
import { defaultJavaScriptEditorValue } from './cloud-data-script/cloud-data-script-controller';
import * as dataScriptService from '../../../services/data-script.service';
import MuiDialog from '../../../shared/mui-dialog/mui-dialog';

export default function ColumnSelectionController(props) {

    const { getColumnSchema, state, resetErrorMessages, setState, prevStepRef,
        setSelectedConnection, saveCollection, isBasicInfoSubmitted, setActiveStep, activeStep, isUpdatingCollection,
        isLoading, setIsLoading, selectedConnection, getCollectionObjectByType, prevCloudFilterationData, isCloudFilterationData,
        setIsCloudFilterationData,queryState,isOpenInDialog = true, handleAuthorizeAgain, isAuthorizeAgain,
        isSearchOptionsAvailable, setIsSearchOptionsAvailable, searchOptionsParameters, setSearchOptionsParameters,
        setShowDataVisualizer, showDataVisualizer, maunalColumns, columnOverriden, setColumnOverriden, prevCloudTransformationScripts, excelDataState, setExcelDataState} = props;


    const [columnsList, setColumnList] = useState([]);
    const [filterColumnsList, setFilterColumnsList] = useState([]);
    const [data, setData] = useState([]);
    const [sortModal, setSortModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [limitModal, setLimitModal] = useState(false);
    const [sortData, setSortData] = useState([]);
    const [limitData, setLimitData] = useState(null);
    const [currentSortIndex, setCurrentSortIndex] = useState();
    const [sortInput, setSortInput] = useState({
        orderBy: "",
        field: ""
    })
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [preview, setPreview] = useState(false);
    const [filterData, setFilterData] = useState([]);    
    const [isNewColumFilter, setIsNewColumFilter] = useState(false);
    const [filterConfig, setFilterConfig] = useState({
        numberOfFilterGroupAllowed: 0,
        numberOfFilterPerGroupAllowed: 0,
        numberOfSortAllowed: 0,
        operatorAllowedInFilterGroup: [],
        operatorAllowedInFilter: [],
        filterTypeArray: [],
        filterGroupExceeded:"",
        filterPerGroupExceeded:"",
        SortExceeded:""
    });
    const [prevFilterationData, setPrevFilterationData] = useState({
        prevFilterData: [],
        prevSortData: [],
        prevLimitData: null,
        templateCollectionId: null
    });
    const [collectionFilterColumns, setCollectionFilterColumns] = useState([]);
    const [isColumnOrderChanged, setIsColumnOrderChanged] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSearchButton, setSelectedSearchButton] = useState(0);
    const { collectionUId: collectionUId } = useParams();
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const [previousTemplateCollectionId,setPreviousTemplateCollectionId] = useState(null);
    const [isColumnCheckedUnchecked, setIsColumnCheckedUnchecked] = useState(false);

    const [openPopup, setOpenPopup] = useState(false);        
    const [customColumn, setCustomColumn] = useState();
    const [datatypeDisplayFormate, setDatatypeDisplayFormate] = useState();
    const [prioritizeEquivalentDataTypes, setPrioritizeEquivalentDataTypes] = useState();
    const [isDynamicTestCollectionTested, setIsDynamicTestCollectionTested] = useState(false);
    const [openDataScriptPopup, setOpenDataScriptPopup] = useState(false);
    const [cloudDataScriptType, setCloudDataScriptType] = useState(null)
    const [testedData, setTestedData] = useState({
        transformedData: [],
        transformedDataColunmsKey: [],
        styleTransformedData: [],
        styleTransformedDataColunmsKey: [],
    })
    const [preDefinedScriptsData, setPreDefinedScriptsData] = useState();
    const [muiDialog, setMuiDialog] = useState(false);
    const [isParameterPopulated, setIsParameterPopulated] = useState(false);

    useEffect(()=>{
        setPrioritizeEquivalentDataTypes(getEquivalentPriorityDataType(state?.displayFormatList));
    },[state?.displayFormatList])    

    useEffect(() => {
        if (!isSearchOptionsAvailable) {
            setSelectedSearchButton(1);
        }
        else {
            setSelectedSearchButton(0);
        }
    }, [isSearchOptionsAvailable])

    useEffect(() => {
        if(state?.templateCollectionId && state?.templateCollectionId  != previousTemplateCollectionId){
            getColumnSchema(state, formatColumList);
            setPreviousTemplateCollectionId(state?.templateCollectionId);
        }

        if (prevCloudFilterationData?.templateCollectionId || prevCloudTransformationScripts?.templateCollectionId){
            handleObjectChange(null, prevCloudFilterationData?.templateCollectionId)
        }
    }, [state?.templateCollectionId, state?.restDataTransformationScript])

    useEffect(() => {
        if (state?.collectionParameters.length > 0 && !isNewColumFilter && !isCloudFilterationData) {

            var filterParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter);
            var sortParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort);
            var limitParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit);
            if (filterParameter && filterParameter?.length > 0) {
                var filter = JSON.parse(filterParameter[0].defaultValue);
                filter = filter.filter(group => group.filters.length > 0);

                filter.forEach((group) => {
                    group.filters.forEach((filter) => {
                        if (filter.dateLabelValue) {
                            const { startDate, endDate } = getDateRange(filter.dateLabelValue, filter?.conditionLabel);
                            if (startDate && endDate){
                                filter.value = startDate;
                                filter.highValue = endDate;
                            }
                        }
                    });
                });

                setFilterData(filter);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevFilterData: filter,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            if (sortParameter && sortParameter?.length > 0) {
                var sort = JSON.parse(sortParameter[0].defaultValue);
                setSortData(sort);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevSortData: sort,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            if (limitParameter && limitParameter?.length > 0) {
                var limit = JSON.parse(limitParameter[0].defaultValue);
                setLimitData(limit);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevLimitData: limit,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            setIsCloudFilterationData(true);
        }
    }, [state?.collectionParameters])
    

    useEffect(() => {
        if (state?.collectionColumnSchema && state?.collectionColumnSchema.length > 0 && !isColumnOrderChanged && !isColumnCheckedUnchecked && !isDynamicTestCollectionTested && isParameterPopulated) {
            executeData(false);            
        }
        setIsColumnCheckedUnchecked(false);
    }, [columnsList, isParameterPopulated])

    useEffect(() => {
        if ((selectedConnection && !state?.templateCollectionId) || collectionUId) {
            getCollectionObjectByType();
        }
    }, [collectionUId]);

    useEffect(() => {
        if (selectedConnection?.connectionTypeId) {
            getCollectionFilterConfig(selectedConnection?.connectionTypeId);
        }
    }, [selectedConnection])

    useEffect(() => {
        getCollectionFilterColunms();
        getCollectionScripts();
    }, [])    

    useEffect(() => {
        if(state?.cloudCollectionError && state?.cloudCollectionError?.data?.data?.access_token){
            getColumnSchema(state, formatColumList);
            setState((prevState) => { return { ...prevState, cloudCollectionError: null } });
        }
    }, [state?.cloudCollectionError])

    useEffect(()=>{
        if(maunalColumns.length > 0 && columnsList.length > 0 && columnOverriden){

            maunalColumns.forEach((manualColumn) => {
                let column = columnsList.find((col) => col.columnName === manualColumn.columnName);
            
                if (column) {
                    Object.assign(column, {
                        displayName: column.displayName,
                        isSelected: column.isSelected || false,
                        dataTypeName: column.dataTypeName, 
                        isManuallyCreated: true,
                        displayFormat: column.displayFormat || manualColumn.displayFormat,
                        isDefault: manualColumn.isDefault,
                        isFilterable: manualColumn.isFilterable,
                        isSortable: manualColumn.isSortable,
                        notQuerable: manualColumn.notQuerable,
                        displayOrder: column.displayOrder 
                    });
                }
            });
            

            const sortedColumnsList = _.orderBy(columnsList,['isSelected', (col) => col?.displayName?.toLowerCase()],['desc', 'asc']);

            setColumnOverriden(false);

            setColumnList(sortedColumnsList);
        }

    },[maunalColumns,columnsList])

    useEffect(() => {
        if (state.testedDataList.length > 0) {
            if (( (state?.cloudDataTransformationScript != null && state?.cloudDataTransformationScript !== defaultJavaScriptEditorValue) || state?.dataTransformationScriptId )){
                // if (testedData?.transformedData?.length == 0) {
                    handleTestScript(null, AppEnum.DataScript.dataTransformationScript)
                // }
            }
            if (( (state?.dataStylingScript != null && state?.dataStylingScript !== defaultJavaScriptEditorValue) || state?.dataStylingScriptId)) {
                // if (testedData?.styleTransformedData?.length == 0) {
                    handleTestScript(null, AppEnum.DataScript.dataStylingScript)
                // }
            }
        }
    }, [state.testedDataList, columnsList])

    const getCollectionFilterColunms = () => {
        collectionService.getCollectionFilterColumns()
            .then((response) => {
                if (response?.data) {
                    setCollectionFilterColumns(response?.data);
                }
            })
    }

    const dataForStyleScript = useMemo(() => {
        if (!testedData?.transformedData?.length) {
            return state?.testedDataList || [];
        } else {
            return testedData.transformedData;
        }
    }, [testedData?.transformedData, state?.testedDataList]);



    const getCollectionFilterConfig = (connectionTypeId) => {
        collectionService.getCollectionFilterConfig(connectionTypeId)
            .then((response) => {
                if (response?.data) {
                    setFilterConfig({
                        numberOfFilterGroupAllowed: response.data?.numberOfFilterGroupAllowed,
                        numberOfFilterPerGroupAllowed: response.data?.numberOfFilterPerGroupAllowed,
                        numberOfSortAllowed: response.data?.numberOfSortAllowed,
                        operatorAllowedInFilterGroup: response.data?.operatorAllowedInFilterGroup,
                        operatorAllowedInFilter: response.data?.operatorAllowedInFilter,
                        filterTypeArray: response.data?.filterTypeArray,
                        filterGroupExceeded: response.data?.filterGroupExceeded,
                        filterPerGroupExceeded: response.data?.filterPerGroupExceeded,
                        SortExceeded: response.data?.sortExceeded
                    });
                }
            })
    }

    const formatColumList = (data, isDynamicColumns = false) => {
        if (data) {
            var columns = data?.map((i) => {
                return {
                    columnName: i.columnName,
                    displayName: i.displayName,
                    isFilterable: i.isFilterable,
                    isSortable: i.isSortable,
                    isDefault: i.isDefault,
                    //isSelected: i.isSelected || isDynamicColumns,
                    isSelected: i.isSelected,
                    dataTypeName: i.dataTypeName,
                    displayFormat: i.displayFormat,
                    isMultiSelect: i.isMultiSelect,
                    options: i.options,
                    notQuerable: i.notQuerable,
                    displayOrder : i.displayOrder,
                    isManuallyCreated : false,
                }
            });
            setState((prevState) => { return { ...prevState, collectionColumnSchema: columns } });

            setIsColumnOrderChanged(false);
            const sortedList = _.orderBy(columns,['isSelected', (col) => col?.displayName?.toLowerCase()],['desc', 'asc']);
            setColumnList(sortedList);
        }
        return [];
    }

    const formateFilterData = () => {
        var colParameters = [...state?.collectionParameters];
        if (filterData?.length > 0) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter).length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter);
                if (prevCollectionParameter.length > 0) {
                    prevCollectionParameter[0].defaultValue = JSON.stringify(filterData?.filter(group => group.filters.length > 0));
                }
            }
            else {
                let collectionParameter = {
                    parameterName: AppEnum.ReservedKeywords.filter,
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(filterData?.filter(group => group.filters.length > 0)),
                    displayName: "Filter",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false,
                    enableSavingParameterValue: false,
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }

        if (sortData.length > 0) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort).length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort);
                prevCollectionParameter[0].defaultValue = JSON.stringify(sortData);
            }
            else {
                let collectionParameter = {
                    parameterName: AppEnum.ReservedKeywords.sort,
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(sortData),
                    displayName: "Sort",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false,
                    enableSavingParameterValue: false,
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }

        if (limitData) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit).length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit);
                prevCollectionParameter[0].defaultValue = JSON.stringify(limitData);
            }
            else {
                let collectionParameter = {
                    parameterName: AppEnum.ReservedKeywords.limit,
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(limitData),
                    displayName: "Limit",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false,
                    enableSavingParameterValue: false,
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }
    };

    const handleOnChangeSelectColumn = (event, index, item) => {

        // setIsColumnCheckedUnchecked(true)
        var newSelectedList = [...columnsList]
        var newFilteredList = [...filterColumnsList]

        if (!preview) {
            // let hasColumnData = true;
            // if ((index == null || index == undefined) && !item && event.target.checked) {
            //     const selectedColumnNames = newSelectedList.map(column => column.columnName);

            //     const allColumnsPresent = selectedColumnNames.every(columnName => {
            //         return state?.testedDataList?.some(dataRow => columnName in dataRow);
            //     });
            
            //     hasColumnData = allColumnsPresent;
            // }
            // else {
            //     hasColumnData = item && state?.testedDataList?.some(data => Object.keys(data)?.includes(item?.columnName));
            // }

            // hasColumnData = !event.target.checked ? true : hasColumnData
            // setPreview(!hasColumnData);
            setPreview(true);
        }
        
        let originalIndex = null;
        if (newFilteredList?.length > 0){
            const columnInFilteredList = newFilteredList[index];
            originalIndex = newSelectedList?.findIndex(i => i.columnName  === columnInFilteredList?.columnName);
        }
        let selectedColumnDisplayOrder = 0;
        if (index == null || index == undefined) {
            if (event.target.checked) {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = true;
                    return i;
                })
            }
            else {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = false;
                    i.displayOrder = 0;
                    return i;
                })
            }
        }
        else {
            let targetIndex = (originalIndex && originalIndex != null) ? originalIndex : index;
            selectedColumnDisplayOrder = newSelectedList[targetIndex].displayOrder;
            newSelectedList[targetIndex].isSelected = event.target.checked;
            newSelectedList[targetIndex].displayOrder = 0;            
        }

        if (newFilteredList?.length > 0) {
            if (index == null || index == undefined) {
                if (event.target.checked) {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = true;
                        return i;
                    })
                }
                else {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = false;
                        return i;
                    })
                }
            }
            else {
                newFilteredList[index].isSelected = event.target.checked;
            }
            setFilterColumnsList(newFilteredList);
        }

        let checkSelected  = newSelectedList.some(i => i.isSelected);
        let maxDisplayOrder = checkSelected ? Math.max(...newSelectedList.filter(i => i.isSelected).map(i => i.displayOrder || 0)) : 0;

        if (maxDisplayOrder !== 0){
            let increment = 1;
            newSelectedList = newSelectedList.map(i => {
                if(selectedColumnDisplayOrder !== 0 && selectedColumnDisplayOrder == maxDisplayOrder){
                    return i;
                }
                else if(selectedColumnDisplayOrder !== 0 && selectedColumnDisplayOrder < maxDisplayOrder){
                    if(i.displayOrder !== 0 && i.displayOrder > selectedColumnDisplayOrder){
                        return { ...i, displayOrder: --i.displayOrder };
                    }
                    return i;
                }
                else if (i.isSelected && (i.displayOrder == undefined || i.displayOrder == null || i.displayOrder == 0)) {
                    const updatedColumn = { ...i, displayOrder: maxDisplayOrder + increment };
                    increment++;
                    return updatedColumn;
                }                
                return i;
            });
        }
        else if(maxDisplayOrder == 0 && checkSelected){
            let increment = 1;
            newSelectedList = newSelectedList.map(i => {                
                if (i.isSelected) {
                    return { ...i, displayOrder: increment++ };
                }
                return i;
            });
        }

        setIsColumnOrderChanged(false);
        setColumnList(newSelectedList);
        setState((prevState) => { return { ...prevState, collectionColumnSchema: newSelectedList } });

    }

    const executeData = (previewTrue = false) => {
        if (preview && !previewTrue) {
            return;
        }
        else if (preview && previewTrue) {
            setPreview(false);
        }

        setSearchValue('');
        setFilterColumnsList([]);
        if (searchInputRef?.current){
            searchInputRef.current.value = '';
        }

        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true, restBody: JSON.stringify(filterData) } });
        resetErrorMessages();

        var cloudCollectionObject = state.cloudCollectionObjects.filter(obj => obj.templateCollectionId === state.templateCollectionId)[0];
        
        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: isSearchOptionsAvailable ? searchOptionsParameters : state?.collectionParameters,
            collectionColumns: preview ? state?.collectionColumnSchema?.filter((i) => i.isSelected) : columnsList?.filter((i) => i.isSelected),
            uId: state.uId,
            returnFlatternData: !cloudCollectionObject?.returnRawData,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: getFilterData(),
            collectionSorts: sortData,
            collectionLimit: limitData,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : cloudCollectionObject?.returnRawData,
            connectionId : selectedConnection?.id,
            savedParameterValue: state.savedParameterValue,
            flattenLikeSql: state?.flattenLikeSql || false,
            excludedProperties: state?.excludedProperties || ""
        }

        formateFilterData();

        testData(testDataCollectionModal,setData,null,null,true,setState,setIsLoading,true,null,null,null,state?.collectionColumnSchema ? state?.collectionColumnSchema?.filter(i => i.isDefault || i.isSelected) : state?.collectionColumns, formatColumList, setIsDynamicTestCollectionTested, handleSendDataToExcel);

    };

    const getFilterData = () => {
        const filterGroups = filterData?.map(group => {
            const filters = group?.filters.map(item => ({
                field: item.field,
                value: item.value,
                operator: item.operator,
                condition: item.condition,
                displayName: item.displayName,
                highValue: item.highValue,
                values: item.values,
                dateLabel: item.dateLabel,
                dataTypeName: item.dataTypeName,
                valueLabel: item.valueLabel,
                dateLabelValue: item.dateLabelValue,
                conditionLabel: item.conditionLabel,
                isMultiSelect: item.isMultiSelect,
            }));
            return { filters: filters, operator: group?.operator };
        });

        if (!filterGroups || filterGroups.length === 0) {
            return [];
        }

        return [{ filterGroups: filterGroups }];
    };

    const openSortModal = () => {
        setSortModal(true);
        setFilterModal(false)
        setLimitModal(false)
    }

    const closeSortModal = () => {
        setSortModal(false);
    }

    const openFilterModal = () => {
        setFilterModal(true);
        setSortModal(false);
        setLimitModal(false)
    }

    const closeFilterModal = () => {
        setFilterModal(false);
    }

    const openLimitModal = () => {
        setLimitModal(true)
        setFilterModal(false);
        setSortModal(false);
    }

    const closeLimitModal = () => {
        setLimitModal(false)
    }

    const handleChangeObject = (isCrossIconClick) => {
        if (excelDataState?.isCommandFromExcel && window.chrome && window.chrome.webview && isCrossIconClick) { //for excel popup close
            window.chrome?.webview?.postMessage('close_window')
        }

        if(showDataVisualizer){
            setShowDataVisualizer(false);
            return;
        }
        else if (queryState?.isForDataCollections){
            navigate(-1);
            return;
        }
        else if(queryState?.CloseWindowOnPopupClose){
            window.close();
        }
        else{
            setActiveStep(activeStep - 1)
        }
    }

    const onChangeSortInputs = (event, input) => {
        if (input == "order") {
            setSortInput((prevState) => {
                return { ...prevState, orderBy: event.target.value }
            });
        }
        else {
            setSortInput((prevState) => {
                return { ...prevState, field: event.target.value }
            });
        }
    }

    const addSort = () => {
        var newSortData = [...sortData];

        if (currentSortIndex != null) {
            newSortData[currentSortIndex].orderBy = sortInput.orderBy;
            newSortData[currentSortIndex].field = sortInput.field;

            setCurrentSortIndex(null);
        }
        else {
            newSortData.push(sortInput);
        }

        setSortData(newSortData);

        setSortInput((prevState) => {
            return { ...prevState, field: "", orderBy: "" }
        });

        setSortModal(false);
    };

    const handleDeleteSort = (index) => {
        var newSortData = [...sortData];
        newSortData.splice(index, 1);
        setSortData(newSortData);
    }

    const handleClickSort = (index) => {
        var currentSortdata = sortData[index];
        setSortInput(currentSortdata);
        setCurrentSortIndex(index)
        setSortModal(true);
    }

    const handleSave = () => {
        if (collectionUId || queryState?.CollectionUId) {
        
            if (excelDataState?.isCommandFromExcel && excelDataState?.isEditMode) {
                executedData(true);
            }
            saveCollection();
        }
        else {
            setIsDialogOpen(true)
        }
    }

    const handleObjectChange = (event, prevCloudObjectUId) => {
        let changedTemplateCollectionId = event ? event.target.value : state?.templateCollectionId;
        let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == changedTemplateCollectionId);
        if (object) {
            const prevData = prevFilterationData?.templateCollectionId ? prevFilterationData: prevCloudFilterationData;
            // setState((prevState) => { return { ...prevState, templateCollectionId: object?.templateCollectionId, restDataTransformationScript: object?.restDataTransformationScript } });
            setState((prevState) => {
                const { dynamicListCollectionModel, dynamicListOptions, ...rest } = prevState;
                return { 
                  ...rest, 
                  templateCollectionId: object?.templateCollectionId, 
                  restDataTransformationScript: object?.restDataTransformationScript,
                  collectionParameters: prevState.collectionParameters.filter(param => 
                    [AppEnum.ReservedKeywords.filter, AppEnum.ReservedKeywords.sort, AppEnum.ReservedKeywords.limit].includes(param.parameterName)
                  )
                };
              });
            if (prevData?.templateCollectionId) {
                if (state?.collectionParameters && (prevData?.templateCollectionId != object?.templateCollectionId)) {
                    var colParameters = [...state?.collectionParameters];
                    if (colParameters?.length > 0) {
                        if (colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter).length > 0) {
                            var filterParam = colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter);
                            if (filterParam.length > 0) {
                                filterParam[0].defaultValue = "[]";
                            }
                        }
                        if (colParameters.filter(i => i.parameterName ==  AppEnum.ReservedKeywords.sort).length > 0) {
                            var sortParam = colParameters.filter(i => i.parameterName ==  AppEnum.ReservedKeywords.sort);
                            if (sortParam.length > 0) {
                                sortParam[0].defaultValue = "[]";
                            }
                        }
                        if (colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit).length > 0) {
                            colParameters = colParameters?.filter(i => i.parameterName !== AppEnum.ReservedKeywords.limit);
                            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
                            // var limitParam = colParameters.filter(i => i.parameterName == "Limit");
                            // if (limitParam) {
                            //     limitParam[0].defaultValue = "[]";
                            // }
                        }
                    }
                    setFilterData([]);
                    setSortData([]);
                    setLimitData(null);
                    closeFilterModal();
                    closeSortModal();
                    closeLimitModal();
                }
                else {
                    if (prevData?.prevFilterData?.length > 0) {
                        setFilterData(prevData?.prevFilterData);
                    }
                    if (prevData?.prevSortData?.length > 0) {
                        setSortData(prevData?.prevSortData);
                    }
                    if (prevData?.prevLimitData) {
                        setLimitData(prevData?.prevLimitData);
                    }
                    setIsNewColumFilter(true);
                }
            }

            if (prevCloudTransformationScripts?.templateCollectionId) {
                if (prevCloudTransformationScripts?.templateCollectionId != object?.templateCollectionId) {
                    setState((prevState) => { return { ...prevState, cloudDataTransformationScript: null, dataStylingScript: null, dataTransformationScriptId: null, dataStylingScriptId: null } });
                }
                else {
                    setState((prevState) => { 
                        return { ...prevState, 
                            cloudDataTransformationScript: prevCloudTransformationScripts?.transformationScript, 
                            dataStylingScript: prevCloudTransformationScripts?.stylingScript, 
                            dataTransformationScriptId: prevCloudTransformationScripts?.transformationScriptId, 
                            dataStylingScriptId: prevCloudTransformationScripts?.stylingScriptId } });
                }
            }
        }
    }

    const onSearchHandler = (e) => {
        let searchedKey = e.target.value
        setSearchValue(searchedKey);
        resetErrorMessages();

        let filteredDataColunmList = [];
        let searchedColunmList = [];

        if (searchedKey) {
            filteredDataColunmList = columnsList;
            searchedColunmList = [];

            _.forEach(filteredDataColunmList, function (column) {
                if (_.includes(column?.displayName?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedColunmList.push(column);
            })
        }
        else{
            setSearchValue('');
            searchInputRef.current.value = '';
        }

            setFilterColumnsList(searchedColunmList);
    }

    const handleSearchOptionsButtonClick = (index) => {
        setSelectedSearchButton(index);
    };

    const executedData = (isSaveAndRun) => {
        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true, restBody: JSON.stringify(filterData) } });
        resetErrorMessages();

        var cloudCollectionObject = state.cloudCollectionObjects.filter(obj => obj.templateCollectionId === state.templateCollectionId)[0];
        var hasDynamicColumn = selectedConnection.schemaSource === AppEnum.SchemaSource.hasDynamicColumn ? (state.collectionColumns && state.collectionColumns.length > 0 ? state.collectionColumns : null) : null;
        
        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: isSearchOptionsAvailable ? searchOptionsParameters : state?.collectionParameters,
            collectionColumns: hasDynamicColumn ? hasDynamicColumn : preview ? state?.collectionColumnSchema?.filter((i) => i.isSelected) : columnsList?.filter((i) => i.isSelected),
            uId: state.uId,
            returnFlatternData: !cloudCollectionObject.returnRawData,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: getFilterData(),
            collectionSorts: sortData,
            collectionLimit: limitData,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : cloudCollectionObject.returnRawData,
            connectionId : selectedConnection?.id,
            flattenLikeSql: state?.flattenLikeSql || false,
            excludedProperties: state?.excludedProperties || ""
        }

        formateFilterData();
        testData(testDataCollectionModal,setData,null,null,true,setState,setIsLoading,true,null,null,null,state?.collectionColumnSchema ? state?.collectionColumnSchema?.filter(i => i.isDefault || i.isSelected) : state?.collectionColumns, formatColumList, setIsDynamicTestCollectionTested, handleSendDataToExcel, isSaveAndRun);

    };

    const handleOpenDialog = (selectColumn) => {
        let column = state?.collectionColumns.find(i=> i?.columnName == selectColumn?.columnName);
        let matchFormat = false;
        let displayFormatList = "";
        if(!column){
            column = columnsList?.find(i=> i?.columnName == selectColumn?.columnName);
            matchFormat = true;
        }

        const listColumn  = columnsList?.find(i => i?.columnName === selectColumn?.columnName);
        if (column && listColumn && column.dataTypeName === AppEnum.DataBaseDataType.Int && listColumn.dataTypeName === AppEnum.DataBaseDataType.Long) {
            column = { ...column, dataTypeName: AppEnum.DataBaseDataType.Long, displayFormat: "" };

            let defaultInfo = getEquivalentPriorityDataType(state?.displayFormatList, column?.dataTypeName);
            let matchDataType =  prioritizeEquivalentDataTypes.find(i=> i?.sysDataTypeId == column?.dataType);

            let dataType = matchDataType ? defaultInfo?.sysDataTypeId : column?.dataType ;
            displayFormatList = state?.displayFormatList.filter(i => i.sysDataTypeId == dataType);
    
            column.dataType = defaultInfo?.sysDataTypeId;
            column.displayFormat = defaultInfo?.displayFormat;
        } 
        else {
            let defaultInfo = getEquivalentPriorityDataType(state?.displayFormatList, column?.dataTypeName);
            let matchDataType =  prioritizeEquivalentDataTypes.find(i=> i?.sysDataTypeId == column?.dataType);

            let dataType = matchDataType ? column?.dataType : defaultInfo?.sysDataTypeId;
            displayFormatList = state?.displayFormatList.filter(i => i.sysDataTypeId == dataType);

            if(matchFormat || !matchDataType){    
                let dataTypeCheck = displayFormatList?.find(i=> i.displayFormat == column.displayFormat);
                column.dataType = dataTypeCheck ? (column.dataType || defaultInfo?.sysDataTypeId) : defaultInfo?.sysDataTypeId;
                column.displayFormat = dataTypeCheck ? (column.displayFormat || defaultInfo?.displayFormat) : defaultInfo?.displayFormat;
            }
        }

        setCustomColumn(column);
        setDatatypeDisplayFormate(displayFormatList);
        setOpenPopup(true);
    }

    const onDisplayNameChange = (value) => {
        setCustomColumn((prevstate) => { return { ...prevstate, displayName: value } });
    };

    const onListClick =(value, field)=>{
        if(field){
            let list = state?.displayFormatList.filter(i => i.sysDataTypeId == value);
            setDatatypeDisplayFormate(list);
            setCustomColumn((prevstate) => { return { ...prevstate, dataTypeName: list[0]?.dataBaseDataType, dataType: value, displayFormat: list[0]?.displayFormat } });
        }
        else {
            setCustomColumn((prevstate) => { return { ...prevstate, displayFormat: value } });
        }
    }

    const onSaveClick =() =>{
        let count = 0;
        let newDisplayName = formatDisplayName(customColumn?.displayName);
        
        _.forEach(columnsList, function (col) {
            if (col?.displayName?.toLowerCase().trim() === newDisplayName?.toLowerCase().trim() && 
                col?.columnName?.toLowerCase().trim() !== customColumn?.columnName?.toLowerCase().trim()) {
                count++;
            }
        })

        if(count > 0){
            toast.error("Duplicate display names are not permitted");
            return;
        }

        _.forEach(columnsList, function (col) {
            if (col?.columnName?.toLowerCase().trim() === customColumn?.columnName?.toLowerCase().trim()) {
                _.set(col, 'dataType', customColumn?.dataType);
                _.set(col, 'displayFormat', customColumn?.displayFormat);
                _.set(col, 'displayName', newDisplayName);
                _.set(col, 'dataTypeName', customColumn?.dataTypeName);
            }
        }) 
        _.forEach(state?.collectionColumns, function (col) {
            if (col?.columnName?.toLowerCase().trim() === customColumn?.columnName?.toLowerCase().trim()) {
                _.set(col, 'dataType', customColumn?.dataType);
                _.set(col, 'dataTypeName', newDisplayName);
                _.set(col, 'displayFormat', customColumn?.displayFormat);
                _.set(col, 'displayName', customColumn?.displayName);
            }
        })
        setOpenPopup(false);
    }

    const handleClosePopup = () =>{
        setOpenPopup(false);
        setOpenDataScriptPopup(false)
        // setCloudDataScriptType(null)
    }

    const handleAddCloudDataScriptPopup = (scriptType, isOpenPopUp, isScriptAlreadyApplied) => { 
        setCloudDataScriptType(scriptType)
        if (isOpenPopUp) {
            setOpenDataScriptPopup(true);
        }
        if (isScriptAlreadyApplied) {
            handleTestScript(null, scriptType);
        }
    }

    
    const handleTestScript = (script, scriptType, testedData) => {
        try {
            const dataToTest = _.cloneDeep(state.testedDataList);
            let evalScript = null;

            if ((scriptType || cloudDataScriptType) == AppEnum.DataScript.dataTransformationScript) {

                if (!script && state?.dataTransformationScriptId) {
                    evalScript = preDefinedScriptsData?.find(i => i.id == state?.dataTransformationScriptId)?.script;
                    if (!evalScript) return;
                }
                else {
                    evalScript = script ? script : state.cloudDataTransformationScript; 
                }
                const dataTransformation = eval(`(${evalScript})`);
                let transformedData = dataTransformation(dataToTest);
    
                let testedDataTransformedColumnsKeys = transformedData?.[0];
                testedDataTransformedColumnsKeys = _.keys(testedDataTransformedColumnsKeys).filter(
                    (key) => !key.endsWith("-style")
                );

                if (!Array.isArray(transformedData)) {
                    toast.error("Warning: Transformed data is not an array.", transformedData);
                    return;
                }
                else if (Array.isArray(testedDataTransformedColumnsKeys) && transformedData?.length === 0) {
                    toast.error("Warning: Columns data is empty.");
                    return;
                }   
                const transformedColumns = getTransformedColumns(testedDataTransformedColumnsKeys, columnsList);
    
                setTestedData((prevState) => { return { ...prevState, transformedData: transformedData, transformedDataColunmsKey: transformedColumns } });
                if((state?.dataTransformationScriptId) || state?.dataStylingScript) {
                    handleTestScript(null, AppEnum.DataScript.dataStylingScript, _.cloneDeep(transformedData));
                }
            }
            if ((scriptType || cloudDataScriptType) == AppEnum.DataScript.dataStylingScript){

                let dtsData = null;
                let transforMationScript = null;

                if (state?.dataTransformationScriptId) {
                    transforMationScript = preDefinedScriptsData?.find(i => i.id == state?.dataTransformationScriptId)?.script;
                    if (!transforMationScript) return;
                }
                else {
                    transforMationScript = state.cloudDataTransformationScript; 
                }
                if (transforMationScript) {
                    const dataTransformationScript = eval(`(${transforMationScript})`);
                    dtsData = dataTransformationScript(dataToTest);
                }
                if (!script && state?.dataStylingScriptId) {
                    evalScript = preDefinedScriptsData?.find(i => i.id == state?.dataStylingScriptId)?.script;
                    if (!evalScript) return;
                }
                else {
                    evalScript = script ? script : state.dataStylingScript ? state.dataStylingScript : defaultJavaScriptEditorValue; 
                }
                const dataStylingTransformation = eval(`(${evalScript})`);
                let transformedStyleData = dataStylingTransformation(testedData || dtsData || dataToTest);
    
                let testedDataStyledColumnsKeys = transformedStyleData?.[0];
                testedDataStyledColumnsKeys = _.keys(testedDataStyledColumnsKeys).filter(
                    (key) => !key.endsWith("-style")
                );

                if (!Array.isArray(transformedStyleData)) {
                    toast.error("Warning: Transformed Style Data is not an array.", transformedStyleData);
                    return;
                }
                else if (Array.isArray(testedDataStyledColumnsKeys) && transformedStyleData?.length === 0) {
                    toast.error("Warning: Columns data is empty.");
                    return;
                }
                const transformedColumns = getTransformedColumns(testedDataStyledColumnsKeys, columnsList);
                setTestedData((prevState) => { return { ...prevState, styleTransformedData: transformedStyleData, styleTransformedDataColunmsKey: transformedColumns } });
    
            }
        } catch (error) {
            console.error("Syntax error in the script:", error.message);
            toast.error(error.message);
            return;
        }
    }

    const handleRemoveTransformation = (scriptType) => {
        if (scriptType == AppEnum.DataScript.dataTransformationScript) {
            setState((prevState) => { return { ...prevState, cloudDataTransformationScript: null } });
            setState((prevState) => { return { ...prevState, dataTransformationScriptId: null } });
            setTestedData((prevState) => { return { ...prevState, transformedData: [], transformedDataColunmsKey: [] } });
            if((state?.dataTransformationScriptId) || state?.dataStylingScript) {
                handleTestScript(null, AppEnum.DataScript.dataStylingScript, _.cloneDeep(state?.testedDataList));
            }
        }
        else {
            setState((prevState) => { return { ...prevState, dataStylingScript: null } });
            setState((prevState) => { return { ...prevState, dataStylingScriptId: null } });
            setTestedData((prevState) => { return { ...prevState, styleTransformedData: [], styleTransformedDataColunmsKey: [] } });
        }
        setMuiDialog(false);
        setCloudDataScriptType(null);
    }

    const getCollectionScripts = () => {
        dataScriptService.getAllScript(true)
            .then((response) => {
                if (response?.data) {
                    setPreDefinedScriptsData(response?.data);
                }
            })
    }

    const confirmRemoveTransformation = (transforMationScriptType) =>{
        setCloudDataScriptType(transforMationScriptType);
        setMuiDialog(true);
    }

    const getTransformedColumns = (testedDataKeys, columnsList, rawData) => {
        if (!testedDataKeys) return [];
      
        const selectedColumnsMap = (columnsList || [])
          .filter(({ isSelected }) => isSelected)
          .reduce((acc, { columnName, displayName }) => {
            acc[columnName] = displayName || columnName;
            return acc;
          }, {});
      
        const rawDataKeys = rawData?.length ? new Set(Object.keys(rawData[0])) : null;
      
        const selectedColumns = testedDataKeys
          .filter(key => selectedColumnsMap[key])
          .map(key => ({ columnName: key, displayName: selectedColumnsMap[key] }));
      
        const extraColumns = testedDataKeys
          .filter(key => !selectedColumnsMap[key] && (!rawDataKeys || !rawDataKeys.has(key)))
          .map(key => ({ columnName: key, displayName: key }));
      
        return [...selectedColumns, ...extraColumns];
    };

    const handleSendDataToExcel= (response, isSaveAndRun) => {
        if (excelDataState?.isCommandFromExcel) {
            const responseData = response;
            setExcelDataState((prevState) => { return { ...prevState, parameters: searchOptionsParameters, data: responseData } });
            let dtScript = state?.cloudDataTransformationScript
            let dsScript = state?.dataStylingScript
            if (state?.dataTransformationScriptId) {
                dtScript = preDefinedScriptsData?.find(i => i.id == state?.dataTransformationScriptId)?.script;
            }
            if (state?.dataStylingScriptId) {
                dsScript = preDefinedScriptsData?.find(i => i.id == state?.dataStylingScriptId)?.script;
            }
            if (!excelDataState?.isEditMode) {
                // handleChangeObject();
                sendDataToWPF({ parameters: searchOptionsParameters, data: responseData, columns: state?.collectionColumnSchema, CloudDTScript: dtScript, DSScript: dsScript });
            }

            if (isSaveAndRun) {
                sendDataToWPF({ parameters: searchOptionsParameters, data: responseData, columns: state?.collectionColumnSchema, CloudDTScript: dtScript, DSScript: dsScript });
            }
        }
    }

    const sendDataToWPF = (data) => {
        if (window.chrome && window.chrome.webview) {
            window.chrome.webview.postMessage(JSON.stringify({ type: "send_data_to_excel", data }));
        }
    }

    return (
        <>
            <ColumnSelectionPopup columnsList={columnsList}
                rowData={state?.testedDataList}
                handleOnChangeSelectColumn={handleOnChangeSelectColumn}
                sortModal={sortModal}
                filterModal={filterModal}
                limitModal={limitModal}
                openSortModal={openSortModal}
                openFilterModal={openFilterModal}
                openLimitModal={openLimitModal}
                closeSortModal={closeSortModal}
                closeFilterModal={closeFilterModal}
                closeLimitModal={closeLimitModal}
                onChangeSortInputs={onChangeSortInputs}
                sortInput={sortInput}
                addSort={addSort}
                sortData={sortData}
                limitData={limitData}
                setLimitData={setLimitData}
                handleDeleteSort={handleDeleteSort}
                handleClickSort={handleClickSort}
                handleSave={handleSave}
                executeData={executeData}
                filterData={filterData}
                setFilterData={setFilterData}
                setSortData={setSortData}
                preview={preview}
                handleChangeObject={handleChangeObject}
                isLoading={isLoading}
                isDialogOpen={isDialogOpen}
                state={state}
                setState={setState}
                selectedConnection={selectedConnection}
                handleObjectChange={handleObjectChange}
                filterConfig={filterConfig}
                onSearchHandler={onSearchHandler}
                filterColumnsList={filterColumnsList}
                collectionFilterColumns={collectionFilterColumns}
                searchInputRef={searchInputRef}
                searchValue={searchValue}
                setColumnList={setColumnList}
                setIsColumnOrderChanged={setIsColumnOrderChanged}
                handleSearchOptionsButtonClick={handleSearchOptionsButtonClick}
                selectedSearchButton={selectedSearchButton}
                isSearchOptionsAvailable={isSearchOptionsAvailable}
                searchOptionsParameters={searchOptionsParameters}
                setSearchOptionsParameters={setSearchOptionsParameters}
                executedData={executedData}
                isOpenInDialog={isOpenInDialog}
                handleAuthorizeAgain={handleAuthorizeAgain}
                isAuthorizeAgain={isAuthorizeAgain}
                handleOpenDialog ={handleOpenDialog}
                openPopup={openPopup}
                handleClosePopup={handleClosePopup}
                onListClick={onListClick}
                onSaveClick={onSaveClick}
                onDisplayNameChange={onDisplayNameChange}
                customColumn={customColumn}
                datatypeDisplayFormate={datatypeDisplayFormate}
                handleAddCloudDataScriptPopup={handleAddCloudDataScriptPopup}
                openDataScriptPopup={openDataScriptPopup}
                setCloudDataScriptType={setCloudDataScriptType}
                cloudDataScriptType={cloudDataScriptType}
                handleTestScript={handleTestScript}
                testedData={testedData}
                handleRemoveTransformation={handleRemoveTransformation}
                preDefinedScriptsData={preDefinedScriptsData}
                prioritizeEquivalentDataTypes={prioritizeEquivalentDataTypes} 
                confirmRemoveTransformation={confirmRemoveTransformation}
                dataForStyleScript={dataForStyleScript}
                muiDialog={muiDialog}
                setMuiDialog={setMuiDialog}
                excelDataState={excelDataState}
                setIsParameterPopulated={setIsParameterPopulated}
                isParameterPopulated={isParameterPopulated}/>
            {isDialogOpen &&
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setSelectedConnection={setSelectedConnection}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    saveCollection={saveCollection}
                    isBasicInfoSubmitted={isBasicInfoSubmitted}
                    isUpdatingCollection={isUpdatingCollection}
                    isCloudLoading={isLoading}
                />}
        </>

    )
}
