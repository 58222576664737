export class DataEditingScriptsState {
  dataScript = [];
  dataScriptHeader = [
    { headerTitle: "Data Script Name" },
    { headerTitle: "Script Type" },
    { headerTitle: "Script Applicable" },
    { headerTitle: "Collections" },
    { headerTitle: " " },
  ];
  isShrink = false;
  filterMenuName = '';
  scriptEnchorEl = null;
  selectedScriptIndex = null;
  isScriptMenuOpen = false;
  isShowPopUp = false;
  scriptUId = "";
  errorMessage = null;
  scriptAsDataSource = 'All'
}

export const defaultScriptValue = "function dataTransformation(rows) {\n  //write your own logic...\n  return rows;\n}"

export class AddNewDataScriptModel {
  name = "";
  scriptTypeCD = "";
  applicableToCD = "";
  script = defaultScriptValue;
  sampleData = "[{}]";
  aSTenantUId = "";
  uId = "";
}