import React, { useEffect, useState } from 'react'
import MergeQuery from '../../../../components/data-collections/merge-collection/merge-query';
import { AppEnum } from '../../../../constants/app-enum';
import { getAllScript } from '../../../../services/data-script.service';

const MergeQueryController = (props) => {

    const { setState, prevStepRef, state, showSaveChangesButton, preDefinedScripts, setPreDefinedScripts } = props;
    const [selectedScriptType, setSelectedScriptType] = useState(AppEnum.MergeCollection.SQL);
    const [savedScripts, setSavedScripts] = useState({
        sqlScript: state.restBody,
        mergeScript: state.mergeScript,
        dataTransformationScriptId : state.dataTransformationScriptId,
    });

    const defaultValue = "function mergeTransformation(collectionData1, collectionData2, collectionData3, collectionData4, collectionData5, collectionData6, collectionData7, collectionData8, collectionData9, collectionData10) {\n  //write your own logic...\n  return collectionData1;\n}"
    
    useEffect(() => {
        if (state.restBody) {
            setSelectedScriptType(AppEnum.MergeCollection.SQL);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.SQL}})
        } else if (state.mergeScript) {
            setSelectedScriptType(AppEnum.MergeCollection.Custom_Script);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Custom_Script}})
        } else if (state.dataTransformationScriptId) {
            setSelectedScriptType(AppEnum.MergeCollection.Predefined_Script);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Predefined_Script}})
        }
    }, []); 

    useEffect(() => {
        setState((prevState) => { 
            if (selectedScriptType === AppEnum.MergeCollection.SQL) {
                return {
                    ...prevState,
                    mergeScript: null,
                    restBody: savedScripts.sqlScript,
                    dataTransformationScriptId : null,
                }
            }
            else if (selectedScriptType === AppEnum.MergeCollection.Custom_Script) {
                return {
                    ...prevState,
                    mergeScript: savedScripts.mergeScript || defaultValue,
                    restBody: "",
                    dataTransformationScriptId : null,
                }
            } else {
                return {
                    ...prevState,
                    mergeScript: null,
                    restBody: "",
                    dataTransformationScriptId: savedScripts.dataTransformationScriptId,
                }
            }
        });
    }, [selectedScriptType]);

    useEffect(async () => {
        if(state.mergeScript == null){
            setState((prevState) => { return { ...prevState, mergeScript : defaultValue } });
        }
        const predDeifnedScriptsData = await getAllScript(true);
        if(predDeifnedScriptsData?.data) {
            setPreDefinedScripts(predDeifnedScriptsData.data);
        }
    },[])

    const handleMergeEditor = (e) => {
        setState((prevState) => { return { ...prevState, mergeScript : e } });
        showSaveChangesButton(false);
        setSavedScripts({
            ...savedScripts,
            mergeScript: e,
        })
    }

    const onChangeSqlEditorHandler = (sqlQuery) => {
        setState((prevState) => { return { ...prevState, restBody: sqlQuery } });
        setSavedScripts({
            ...savedScripts,
            sqlScript: sqlQuery
        })
    }

    const handleRadioChange = (event) => {
        setSelectedScriptType(event.target.value);
        setState((prevState) => { return {...prevState, selectedScriptType: event.target.value}})
    };

    const handlePreDefinedScriptChange = (event) => {
        setState((prevState) => { return {...prevState, dataTransformationScriptId: event.target.value}})
        setSavedScripts({
            ...savedScripts,
            dataTransformationScriptId: event.target.value,
        })
        setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Predefined_Script}});
    }

    const groupedPreDefinedScrips = () => {
        const asTenantUId = sessionStorage.getItem("ASTenantId") || localStorage.getItem("ASTenantId");
        
        const userScripts = preDefinedScripts?.filter((script) => script.asTenantUId === asTenantUId);
        const templateScripts = preDefinedScripts?.filter((script) => script.asTenantUId !== asTenantUId);
        
        return {userScripts, templateScripts};
    }

    return (
        <MergeQuery
            onChangeSqlEditorHandler={onChangeSqlEditorHandler}
            sqlEditorValue={state.restBody}
            mergeSriptValue={state.mergeScript}
            dataTransformationScriptIdValue={state.dataTransformationScriptId}
            handleMergeEditor={handleMergeEditor}
            selectedScriptType={selectedScriptType}
            handleRadioChange={handleRadioChange}
            predDeifnedScripts={preDefinedScripts}
            handlePreDefinedScriptChange={handlePreDefinedScriptChange}
            groupedPreDefinedScrips={groupedPreDefinedScrips}
        />
    )
}

export default MergeQueryController