import React, { useEffect, useState } from "react";
import CloudDataScript from "../../../../components/data-collections/column-selection-popup/cloud-data-script/cloud-data-script";
import { AppEnum } from "../../../../constants/app-enum";
import _ from 'lodash';


export const defaultJavaScriptEditorValue = "function dataTransformation(rows) {\n  //write your own logic...\n  return rows;\n}"

const CloudDataScriptController = (props) => {

    const { state, setState, prevStepRef, handleClosePopup, isOpenDialog, setCloudDataScriptType, cloudDataScriptType, handleTestScript,
            testedData, preDefinedScriptsData, isStylingScriptApplied, isTransformationScriptApplied, dataForStyleScript } = props;

    const [activeEditorTabView, setEditorTabView] = useState("result");
    const [customOrPreDefineScript, setCustomOrPreDefineScript] = useState("preDefine");
    const [tableOrJsonView, setTableOrJsonView] = useState("table");
    const [dataScripts, setDataScripts] = useState({
        transformationScript: null,
        transformationScriptId : null,
        stylingScript: null,
        stylingScriptId: null,
    })
    const [preDefinedScripts, setPreDefinedScripts] = useState([]);

    useEffect(() => {
        if (state.cloudDataTransformationScript == null || state.cloudDataTransformationScript == defaultJavaScriptEditorValue) {
            setDataScripts((prevState) => { return { ...prevState, transformationScript: defaultJavaScriptEditorValue } });
            setState((prevState) => { return { ...prevState, cloudDataTransformationScript: defaultJavaScriptEditorValue } });
        }
        else {
            setDataScripts((prevState) => { return { ...prevState, transformationScript: state.cloudDataTransformationScript } });
        }
        if (state?.dataStylingScript == null || state.dataStylingScript == defaultJavaScriptEditorValue) {
            setDataScripts((prevState) => { return { ...prevState, stylingScript: defaultJavaScriptEditorValue } });
            setState((prevState) => { return { ...prevState, dataStylingScript: defaultJavaScriptEditorValue } });
        }
        else {
            setDataScripts((prevState) => { return { ...prevState, stylingScript: state?.dataStylingScript } });
        }

        if (state?.dataTransformationScriptId) {
            setDataScripts((prevState) => { return { ...prevState, transformationScriptId: state?.dataTransformationScriptId } });
        }
        if (state?.dataStylingScriptId) {
            setDataScripts((prevState) => { return { ...prevState, stylingScriptId: state?.dataStylingScriptId } });
        }
        
        if (preDefinedScriptsData?.length > 0) {
            const scripts = cloudDataScriptType == AppEnum.DataScript.dataTransformationScript ? 
                                    preDefinedScriptsData?.filter(i => (i.scriptTypeCD == AppEnum.DataScriptApplicable.RESPONSE_TRANSFORMATION))
                                    : preDefinedScriptsData?.filter(i => (i.scriptTypeCD == AppEnum.DataScriptApplicable.RESPONSE_STYLING));
            setPreDefinedScripts(scripts);
        }

        handleScriptTypeState();
        setEditorTabView("result");


    }, [isOpenDialog])

    const handleScriptTypeState = () => {
        if (cloudDataScriptType == AppEnum.DataScript.dataTransformationScript) {
            if (!isTransformationScriptApplied || state?.dataTransformationScriptId) {
                setCustomOrPreDefineScript("preDefine")
            }
            else {
                setCustomOrPreDefineScript("customScript")
            }
        }
        else {
            if (!isStylingScriptApplied || state?.dataStylingScriptId) {
                setCustomOrPreDefineScript("preDefine")
            }
            else {
                setCustomOrPreDefineScript("customScript")
            }
        }
    }

    const handleEditor = (e) => {
        if (cloudDataScriptType == AppEnum.DataScript.dataTransformationScript) {
            setDataScripts((prevState) => { return { ...prevState, transformationScript: e } });
        }
        else {
            setDataScripts((prevState) => { return { ...prevState, stylingScript: e } });
        }
    }

    const handleScriptTypeChange = (e) => {
        setCloudDataScriptType(e.target.value);
    }

    const handleTestScriptData = () => {
        let script = null;
        if (customOrPreDefineScript != "preDefine") {
            script = cloudDataScriptType == AppEnum.DataScript.dataTransformationScript ? dataScripts?.transformationScript : dataScripts?.stylingScript;
        }
        else {
            const scriptId = cloudDataScriptType == AppEnum.DataScript.dataTransformationScript ? dataScripts?.transformationScriptId : dataScripts?.stylingScriptId;
            script = preDefinedScripts?.find(i => i.id == scriptId)?.script;
        }
        handleTestScript(script);
        setEditorTabView("result");
    }

    const handleRadioOptionChange = (event) => {
        if (event.target.value == "preDefine") {
            setEditorTabView("result");
        }
        setCustomOrPreDefineScript(event.target.value);
    }

    const handleCancel = () => {
        handleClosePopup()
        handleTestScript();
    }

    const handleApplyScript = () => {
        if (customOrPreDefineScript == "customScript") {
            if (cloudDataScriptType == AppEnum.DataScript.dataTransformationScript) {
                setState((prevState) => { return { ...prevState, cloudDataTransformationScript: dataScripts?.transformationScript } });
                setState((prevState) => { return { ...prevState, dataTransformationScriptId: null } });
            }
            else {
                setState((prevState) => { return { ...prevState, dataStylingScript: dataScripts?.stylingScript } });
                setState((prevState) => { return { ...prevState, dataStylingScriptId: null } });
            }
        }
        else {
            if (cloudDataScriptType == AppEnum.DataScript.dataTransformationScript) {
                setState((prevState) => { return { ...prevState, dataTransformationScriptId: dataScripts?.transformationScriptId } });
                setState((prevState) => { return { ...prevState, cloudDataTransformationScript: null } });
            }
            else {
                setState((prevState) => { return { ...prevState, dataStylingScriptId: dataScripts?.stylingScriptId } });
                setState((prevState) => { return { ...prevState, dataStylingScript: null } });
            }
        }
        handleTestScriptData();
        handleClosePopup()
    }

    const handlePreDefineDropdownChange = (e) => {
        if (cloudDataScriptType == AppEnum.DataScript.dataTransformationScript) {
            setDataScripts((prevState) => { return { ...prevState, transformationScriptId: e.target.value } });
        }
        else {
            setDataScripts((prevState) => { return { ...prevState, stylingScriptId: e.target.value } });
        }
    }

    return (
        <CloudDataScript state={state}
            prevStepRef={prevStepRef}
            handleEditor={handleEditor}
            handleClosePopup={handleClosePopup}
            isOpenDialog={isOpenDialog}
            handleScriptTypeChange={handleScriptTypeChange}
            scriptType={cloudDataScriptType}
            handleTestScript={handleTestScriptData}
            setEditorTabView={setEditorTabView}
            activeEditorTabView={activeEditorTabView}
            customOrPreDefineScript={customOrPreDefineScript}
            handleRadioOptionChange={handleRadioOptionChange}
            handleCancel={handleCancel}
            tableOrJsonView={tableOrJsonView} 
            testedData={testedData}
            setTableOrJsonView={setTableOrJsonView}
            handleApplyScript={handleApplyScript}
            handlePreDefineDropdownChange={handlePreDefineDropdownChange}
            preDefinedScripts={preDefinedScripts}
            dataScripts={dataScripts}
            dataForStyleScript={dataForStyleScript}/>
    );
}

export default CloudDataScriptController;
