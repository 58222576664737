import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { ASStepper } from "../../../shared/stepper/as-stepper";
import "./add-new-data-collection.scss";
import BasicInformationController from "./basic-information/basic-information-controller";
import CollectionTypeAndObjectController from "./collection-type-and-object/collection-type-and-object-controller";
import CollectionParametersController from "./collection-parameters/collection-parameters-controller";
import SelectColumnsController from "./select-columns/select-columns-controller";
import TestDataController from "./test-data/test-data-controller";
import { CollectionFilterColumn, CollectionParametersState, DataCollectionModal, DataCollectionState, MergeCollectionState } from "./new-data-collection-state";
import * as connectionService from '../../../services/data-source.service';
import * as collectionService from '../../../services/data-collection.service';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import themeColor from "./../../../styles/_exports.module.scss";
import SendBodyAsController from "./send-body-as/send-body-as";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import DataTransformationScriptController from "./data-transformation-script/data-transformation-script";
import DataAfterTransformationController from "./data-after-transformation/data-after-transformation";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectConnectionController from "./select-connection/select-connection-controller";
import SelectObjectController from "./select-object/select-object-controller";
import ColumnSelectionController from "../column-selection/column-selection";
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { CollectionStepLabelConstant } from "../../../constants/as-stepper-step-label";
import { NewUserModel } from "../../connections/variables/variables-Popup";
import { DynamicList } from "../../connections/variables/variables-Popup";
import { UpdateUserModel } from "../../connections/variables/variables-Popup";
import CollectionFilterColumnController from "./collection-filter-column/collection-filter-column-controller";
import { getTemplateCompany } from '../../../services/variable-service'
import { useASContext } from "../../context-api/as-context";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { AppEnum } from "../../../constants/app-enum";
import { authorizeAgain, getToken, openOtpPopupAuthorization } from "../../../services/authorize-again-service";
import { testData } from "../../../services/test-data.service";
import { getCollectionPermissionByCollectionUId } from "../../../services/user-service";
import { checkOtherParams, filterCollectionParams } from "../DataVisualizer/data-visualizer-controller";
import { getTemplateCollectionParameters, updateParameterValues } from "../utils/template-collection-parameter";
import { updateColumnDataTypes } from "./select-columns/datatypes-equivalent-map";
import { DataCollectionConstants } from "../../../constants/data-collection-consts";
import MergeCollectionSteps from "./merge-collection/merge-collection-steps";
import { getAllScript } from "../../../services/data-script.service";


export const tableBorderRight = { borderRight: themeColor.borderBottom };
export const keyWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const valueWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const parameterTypeWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const valueTypeWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const variableNameWidth = { width: "190px", borderRight: themeColor.borderBottom };

export const findDisplayFormat = (dataTypeName, state) => {
    if (state?.displayFormatList.length > 0) {
        if (dataTypeName?.toLowerCase() == AppEnum.OtherDataBaseType.Number){
            dataTypeName = "Int64";
        }
        return state?.displayFormatList?.find(item => item.dataBaseDataType?.toLowerCase() == dataTypeName?.toLowerCase());
    }
};

export function AddNewDataCollection() {

    const [state, setState] = useState(new DataCollectionState());
    const [mergeCollectionState, setMergeCollectionState] = useState(new MergeCollectionState());
    const [parametersState, setParametersState] = useState(new CollectionParametersState());
    const [extractedParamters, setExtractedParamters] = useState([]);
    const [dataCollectionModal, setDataCollectionModal] = useState(new DataCollectionModal());
    const [collectionFilterColumnState, setCollectionFilterColumnState] = useState(new CollectionFilterColumn());
    const [activeStep, setActiveStep] = useState(0);
    const [selectedConnection, setSelectedConnection] = useState({});
    const [isAuthorizeAgain, setIsAuthorizeAgain] = useState(false);
    const [activeStatus, setActiveStatus] = useState(false);
    const { collectionUId: collectionUId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const prevStepRef = useRef(null);
    const [isTemplateCollection, setIsTemplateCollection] = useState(false);
    const [initialCollectionName, setInitialCollectionName] = useState("");
    const [isSavingCollection, setIsSavingCollection] = useState(false);
    const [stepperViewFromConnection, setStepperViewFromConnection] = useState(false);
    const [isTestedData, setIsTestedData] = useState(false);
    const [isChangedDataParams, setIsChangedDataParams] = useState(false);
    const [isShowHeader, setIsShowHeader] = useState(false);
    const [isCloudLoading, setIsCloudLoading] = useState(false);
    const [isDynamicListValues, setIsDynamicListValues] = useState(
        new DynamicList()
    );
    const [variableUId, setVariableUId] = useState("");
    const [isVariableUsedInCollection, setIsVariableUsedInCollection] = useState(false);
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);
    const [isManualColumnValid, setIsManualColumnValid] = useState(true);
    const [prevCloudFilterationData, setPrevCloudFilterationData] = useState({
        prevFilterData: [],
        prevSortData: [],
        prevLimitData: null,
        templateCollectionId: null
    });
    const [isCloudFilterationData, setIsCloudFilterationData] = useState(false);
    const [openConnectionPopup, setOpenConnectionPopup] = useState(false);
    const [stateConnectionParameter, setStateConnectionParameter] = useState(false);
    const [isStepDataChanged, setIsStepDataChanged] = useState(false); // if any step data gets updated.
    const [showTestCollectionPopup, setShowTestCollectionPopup] = useState(false);
    const [isTestedAfterSaveAppears, setIsTestedAfterSaveAppears] = useState(false);
    const asContext = useASContext();
    const [isSearchOptionsAvailable, setIsSearchOptionsAvailable] = useState(false);
    const [searchOptionsParameters, setSearchOptionsParameters] = useState([]);
    const [maunalColumns, setManualColumns] = useState([]);
    const [columnOverriden, setColumnOverriden] = useState(false);
    const [preDefinedScripts, setPreDefinedScripts] = useState();
    const [isDataModified,setIsDataModified] = useState(false);
    const [prevCloudTransformationScripts, setPrevCloudTransformationScripts] = useState({ //state to manage object change in DV
        transformationScript: null,
        stylingScript: null,
        transformationScriptId: null,
        stylingScriptId: null,
        templateCollectionId: null
    });

    useEffect(() => {
        if (!(asContext.subscriptionValidation.isSubscriptionExist)) {
            navigate("/data-collections");
        }
    }, [asContext.subscriptionValidation.isSubscriptionExist]);

    useEffect(() => {
        if (location.pathname.includes(AppEnum.WebPages.UpdateCollection) && asContext?.tenants?.selectedTenant?.UserRoleId === AppEnum.UserRoleIds.CollectionAdmin) {

            getCollectionPermissionByCollectionUId(collectionUId).then((response) => {
                if (response) {
                    if (!response.data) {
                        navigate("/unauthorized-resource", { state: { from: location.pathname } });
                    }
                }
            });

        }
    }, [asContext?.tenants?.selectedTenant])

    useEffect(() => {

        getConnectionList();
        getCategoryList();
        getDisplayFormatList();
        if (collectionUId) {
            setState((prevState) => { return { ...prevState, uId: collectionUId } });
            getCollectionDetails();
        }
    }, []);

    useEffect(() => {
        if (collectionUId && !state?.templateCollectionId && !isTestedData && (state?.testCollectionError || isAuthorizeAgain)) {
            let jumpToStep = state?.sysCollectionTypeId ===  AppEnum.SysCollectionTypeId.REST ? 4 : 3;// index values for test-data steps


            if (activeStep != jumpToStep)
                setActiveStep(jumpToStep);
        }
    }, [isAuthorizeAgain, state?.testCollectionError]);

    useEffect(() => {
        if (state.connectionUId && collectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId, collectionUId])

    useEffect(() => {
        let isOnlyForHeader = isShowHeader ? true : state.testedDataList?.length > 0
        if (state?.collectionColumns.length > 0 && state?.columnSchemaList?.length == 0 && state?.displayFormatList?.length > 0 && !state?.templateCollectionId && isOnlyForHeader) {

            const hasColumnDataType = state?.columnSchemaList.some(column => column.ColumnDataType && column.ColumnDataType !== null);
            if (hasColumnDataType)
                return;

            const updatedColumns = state?.collectionColumns.map(column => ({
                ...column,
                ColumnDataType: state?.displayFormatList?.find(i => i.sysDataTypeId === column?.dataType)?.dataBaseDataType,
                isSelected: true
            }));

            const updatedDataTypeColumnsList = updateColumnDataTypes(state?.displayFormatList, updatedColumns);
            const sortedList = _.orderBy(
                updatedDataTypeColumnsList,
                [(col) => col.displayOrder === 0 ? Number.MAX_SAFE_INTEGER : col.displayOrder],
                ['asc']
            );

            setState(prevState => ({
                ...prevState,
                columnSchemaList: sortedList,
                collectionColumns: sortedList
            }));
            setIsTestedData(true);
        }
    }, [state?.collectionColumns, state?.displayFormatList, state.testedDataList]);

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true) {
                    setIsTemplateCompany(true);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }
        fetchTemplateCompany();
    }, []);


    useEffect(() => {
        if (state.hasMergeCollections) {
            if (!asContext.globalSpinner?.isGlobalSpinner && activeStep > 3){
                asContext?.globalSpinner?.setIsGlobalSpinner(true);
            }
            else {
                asContext?.globalSpinner?.setIsGlobalSpinner(false);
            }
        }
        else {
            asContext?.globalSpinner?.setIsGlobalSpinner(true);
        }

    }, [activeStep, state.hasMergeCollections])

    useEffect(() => {
        if (state?.collectionParameters.length > 0 && prevCloudFilterationData?.templateCollectionId === null && collectionUId) {
            var filterParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter);
            var sortParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort);
            var limitParameter = state?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit);
            if (filterParameter && filterParameter?.length > 0) {
                var filter = JSON.parse(filterParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevFilterData: filter,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
            if (sortParameter && sortParameter?.length > 0) {
                var sort = JSON.parse(sortParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevSortData: sort,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
            if (limitParameter && limitParameter?.length > 0) {
                var limit = JSON.parse(limitParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevLimitData: limit,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
        }
    }, [state?.collectionParameters])

    useEffect(() => {
        if (state?.cloudCollectionObjects.length > 0 && state?.templateCollectionId) {
            let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state?.templateCollectionId);
            if (object){
                getTemplateCollectionParameters(object?.templateCollectionUId, setState, setIsSearchOptionsAvailable, setSearchOptionsParameters, setManualColumns, setColumnOverriden, collectionUId, state);
            }
        }
    }, [state?.cloudCollectionObjects, state?.templateCollectionId])

    useEffect(async () => {
        if (state?.hasMergeCollections) {
            const preDeifnedScriptsData = await getAllScript(true);
            if (preDeifnedScriptsData?.data) {
                setPreDefinedScripts(preDeifnedScriptsData?.data);
            }
        }
    },[state?.hasMergeCollections])

    useEffect(() => {
        if (prevCloudTransformationScripts?.templateCollectionId === null && collectionUId) {
            setPrevCloudTransformationScripts(prevState => ({
                ...prevState,
                transformationScript: state?.cloudDataTransformationScript,
                stylingScript: state?.dataStylingScript,
                transformationScriptId: state?.dataTransformationScriptId,
                stylingScriptId:  state?.dataStylingScriptId,
                templateCollectionId: state?.templateCollectionId
            }));
        }
    }, [state?.cloudDataTransformationScript, state?.dataStylingScript, state?.dataStylingScriptId, state?.dataTransformationScriptId])

    
    // it fetches collection details by its collectionUId
    const getCollectionDetails = () => {

        collectionService.getCollectionDetails(collectionUId)
            .then((response) => {
                if (response?.data) {

                    let collection = location?.state;
                    let prevCollection = response?.data;
                    setInitialCollectionName(prevCollection.name);

                    prevCollection.connectionUId = collection?.connection?.uId ? collection?.connection?.uId : prevCollection?.connectionUId;
                    prevCollection.categoryUId = collection?.category?.categoryUId ? collection?.category?.categoryUId : prevCollection?.categoryUId;

                    if (prevCollection.isCollectionAsDataSource && prevCollection?.variable != null) {
                        setVariableUId(prevCollection?.variable?.uId);
                        setIsVariableUsedInCollection(prevCollection?.variable?.isUsedInCollection);
                        const parsedData = JSON.parse(
                            prevCollection?.variable?.complexTypeValue
                        );
                        if (typeof parsedData.ValueColumn === "string") {
                            parsedData.ValueColumn = [parsedData.ValueColumn];
                        }  
                        setIsDynamicListValues({ ...parsedData, isDefaultValueCorrect: parsedData?.isDefaultValueCorrect ?? true });

                    }

                    if (prevCollection?.sourceNamePart2?.length == 0) {
                        prevCollection.sourceNamePart2 = prevCollection.name;
                    }

                    if (prevCollection) {
                        setState((prevState) => {

                            return {
                                ...prevState,
                                hasMergeCollections: prevCollection.hasMergeCollections,
                                collectionUIdsToMerge: prevCollection.collectionUIdsToMerge,
                                sysCollectionTypeId: prevCollection.sysCollectionTypeId,
                                sysCollectionSubTypeId: prevCollection.sysCollectionSubTypeId,
                                sysConnectionTypeId: prevCollection.sysConnectionTypeId,
                                childGroupName: prevCollection.childGroupName,
                                groupName: prevCollection.groupName,
                                sourceName: prevCollection.sourceName,
                                sourceNamePart2: prevCollection.sourceNamePart2,
                                sourceNamePart4: prevCollection.sourceNamePart4,
                                isCollectionAsDataSource: prevCollection.isCollectionAsDataSource,
                                hasPrintableCollection: prevCollection.hasPrintableCollection,
                                collectionColumns: prevCollection.collectionColumns,
                                collectionParameters: _.orderBy(prevCollection.collectionParameters, ['displayOrder', 'id'], ['asc', 'asc']),
                                collectionFilterColumns: prevCollection.collectionFilterColumns,
                                name: prevCollection.name,
                                connectionUId: prevCollection.connectionUId,
                                categoryUId: prevCollection.categoryUId,
                                restSendAsCD: prevCollection.restSendAsCD,
                                restDataTransformationScript: prevCollection.restDataTransformationScript,
                                restBody: prevCollection.restBody,
                                restRequestMethod: prevCollection.restRequestMethod,
                                prevCollection: prevCollection,
                                basicInfoError: null,
                                templateCollectionId: prevCollection.templateCollectionId,
                                isFilterSupported: prevCollection.isFilterSupported,
                                isSortSupported: prevCollection.isSortSupported,
                                isPagingSupported: prevCollection.isPagingSupported,
                                areAllFieldsFilterable: prevCollection.areAllFieldsFilterable,
                                areAllFieldsSortable: prevCollection.areAllFieldsSortable,
                                columnApiEndpoint: prevCollection.columnApiEndpoint,
                                variableId: prevCollection.variableId,
                                returnRawData: prevCollection.returnRawData,
                                variable: prevCollection.variable,
                                combineMergedParameters: prevCollection.combineMergedParameters,
                                mergeScript: prevCollection.mergeScript,
                                dataTransformationScriptId: prevCollection.dataTransformationScriptId,
                                dataStylingScriptId: prevCollection.dataStylingScriptId,
                                dataStylingScript: prevCollection.dataStylingScript,
                                savedParameterValue: prevCollection.savedParameterValue,
                                useTemplateConnection: prevCollection.useTemplateConnection,
                                cloudDataTransformationScript: prevCollection.cloudDataTransformationScript,
                                flattenLikeSql: prevCollection.flattenLikeSql || false,
                                excludedProperties: prevCollection.excludedProperties || "",
                            }
                        })
                        if (prevCollection.isTemplateCollection && prevCollection.isInheritCollection) {
                            setState((prevState) => { return { ...prevState, uId: null } });
                            setIsTemplateCollection(true);
                        }

                        if (prevCollection?.collectionParameters?.length > 0) {
                            const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);

                            if (hasOtherParams) {
                                prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                                let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                setIsSearchOptionsAvailable(true);
                                setSearchOptionsParameters(updatedParams);
                            }
                            else {
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                            }
                        }
                        else {
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }

                    }
                    const sysConnectionTypeId = collection?.connection?.sysConnectionTypeId ? collection?.connection?.sysConnectionTypeId : prevCollection?.sysConnectionTypeId;
                    const sysCollectionTypeId = collection?.sysCollectionTypeId ? collection?.sysCollectionTypeId : prevCollection?.sysCollectionTypeId;
                    const templateCollectionId = collection?.templateCollectionId ? collection?.templateCollectionId : prevCollection?.templateCollectionId;

                    getCollectionTypeList(sysConnectionTypeId);
                    if (sysCollectionTypeId != AppEnum?.SysCollectionTypeId?.REST && !templateCollectionId && !prevCollection?.hasMergeCollections) {
                        getColumnSchema(prevCollection);
                    }
                    else if (collectionUId && !isTestedData && !templateCollectionId && !prevCollection?.hasMergeCollections) {
                        getTestedCollection(prevCollection);
                    }
                    setActiveStatus(response.data.isActive);
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }

    const getDisplayFormatList = () => {

        collectionService.getDisplayFormats()
            .then((response) => {

                if (response?.hasError)
                    setState((prevState) => { return { ...prevState, columnSchemaError: response.errorMessage } });

                else if (response?.data) {
                    setState((prevState) => { return { ...prevState, displayFormatList: response?.data } });
                    resetErrorMessages();
                }
            })
    }

    // it gets the collection type list according to the sysConnectionTypeId
    const getCollectionTypeList = (sysConnectionTypeId) => {

        collectionService.getCollectionTypeList(sysConnectionTypeId)
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, collectionTypeList: response.data, sysConnectionTypeId: sysConnectionTypeId } });
            })
    }

    // it fetches the column schema 
    const getColumnSchema = async (prevCollection, callback = null) => {
        setIsCloudLoading(true);
        resetErrorMessages();
        let dataCollectionModal;
        if (prevCollection) {
            dataCollectionModal = getDataCollectionModal(prevCollection);
        }
        else {
            dataCollectionModal = getDataCollectionModal(null);
        }

        if (collectionUId && state?.templateCollectionId) {
            delete dataCollectionModal.collectionParameters;
        }
        else {
            delete dataCollectionModal.collectionColumns;
        }

        var response = await collectionService.getColumnSchema(dataCollectionModal);
        if (response) {
            if (response?.hasError) {
                setState((prevState) => { return { ...prevState, columnSchemaError: response?.errorMessage, columnSchemaList: [] } });
                setIsCloudLoading(false);

                setState((prevState) => { return { ...prevState, cloudCollectionError: response } });

                if (response?.errorMessage == "Unauthorized") {
                    toast.error("Your connection has been expired. Please establish the connection again");
                }
                else {
                    toast.error(response?.errorMessage);
                }
            }
            else {
                setState((prevState) => { return { ...prevState, collectionColumnSchema: response?.data?.data } });
                //handleColumnSchema(response?.data?.data,prevCollection);
                if (!isTestedData && collectionUId && (!prevCollection?.templateCollectionId || !state?.templateCollectionId) && (!state?.collectionColumnSchema || state?.collectionColumnSchema.length < 0)) {
                    prevCollection.collectionColumnSchema = response?.data?.data;
                    getTestedCollection(prevCollection);
                }
                if (callback != null) {
                    callback(response?.data?.data);
                }
                setIsCloudLoading(false);
            }
        }
    }


    // it fetches connection list
    const getConnectionList = () => {

        connectionService.getDataSources(true)
            .then((response) => {

                if (response?.data) {
                    // let connectionList = response.data;
                    let connectionList = response.data.concat(DataCollectionConstants.MergeQueryObj);
                    connectionList = connectionList.filter( item => item.sysConnectionTypeId !== AppEnum.MergeCollection.MergeSysConnectionTypeId || item.uId === AppEnum.MergeCollection.MergeCollectionUId);

                    setState((prevState) => { return { ...prevState, connectionList: connectionList } });
                    resetErrorMessages();
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }

    // it fetches category list
    const getCategoryList = () => {

        collectionService.getCategoryList()
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, categoryList: response.data } })
            })
    }

    // it contains all the basic info details to show when the first step is complete 
    const submittedBasicInfo = useMemo(() => {
        return [
            !selectedConnection?.templateConnectionId ? null : {
                label: 'Data Connection',
                value: _.find(state?.connectionList, (i) => i?.uId === state.connectionUId)?.name
            },
            { label: 'Data Collection Name', value: state.name },
            {
                label: 'Group Name',
                value: `${state.groupName} ${state.childGroupName ? ` > ${state.childGroupName}` : ''}`
            }
        ]?.filter(item => item !== null);

    }, [selectedConnection?.templateConnectionId, state.connectionList, state.connectionUId, state.name, state.groupName, state.childGroupName]);


    const selectedConnectionSubmited = useMemo(() => {

        return [
            {
                label: 'Data Connection',
                value: state.hasMergeCollections ? DataCollectionConstants.MergeQueryObj.name : _.find(state?.connectionList, function (i) {
                    return i?.uId === state.connectionUId;
                })?.name
            } 
        ];
    }, [state.connectionList, state.connectionUId, state.hasMergeCollections]);

    // it contains all the collection type and object details to show when the second step is complete 
    const submittedCollectionType = useMemo(() => {
        const typeDesc = state?.collectionTypeList.find((i) => i?.id === state.sysCollectionTypeId)?.typeDesc;

        return [
            selectedConnection?.templateConnectionId ? state?.collectionTypeList.length > 0
                ? { label: 'From', value: typeDesc }
                : null
                : { label: 'Collection Type', value: typeDesc },
            selectedConnection?.templateConnectionId ? null
                : {
                    label: state.uiLabel,
                    value: (selectedConnection && selectedConnection.connectionTypeId == 2)
                        ? selectedConnection?.parameters?.find((i) => i.parameterName == RestServiceConstants.API_ENDPOINT)?.parameterValue + state?.sourceName
                        : state?.sourceName
                }
        ]?.filter(item => item !== null);

    }, [selectedConnection?.templateConnectionId, state?.collectionTypeList, state.sysCollectionTypeId, state.uiLabel, selectedConnection, state?.sourceName, selectedConnection?.parameters, selectedConnection.connectionTypeId]);


    const submittedCloudObject = useMemo(() => {
        return [
            {
                label: "",
                value: state?.cloudCollectionObjects?.find(i => i.templateCollectionId === state?.templateCollectionId)?.name
            }
        ];
    }, [state?.cloudCollectionObjects, state?.templateCollectionId]);


    // it adds a new collection
    const addNewDataCollection = () => {
        resetErrorMessages();
        setIsSavingCollection(true);

        let dataCollectionModal = getDataCollectionModal();

        if (dataCollectionModal.isCollectionAsDataSource) {
            const valueColumn = isDynamicListValues?.ValueColumn;
            const newComplexTypeValue = {
                ...isDynamicListValues,
                ValueColumn: valueColumn.length === 1 ? valueColumn[0] : valueColumn,
            };
            if (variableUId.length > 0) {
                let updateUserModel = { ...UpdateUserModel };
                updateUserModel.uId = variableUId;
                updateUserModel.name = dataCollectionModal.name;
                updateUserModel.description = "";
                updateUserModel.sysDataTypeId = 8;
                updateUserModel.templateConnectionId = dataCollectionModal?.variable?.templateConnectionId;
                updateUserModel.complexTypeValue = JSON.stringify(newComplexTypeValue);
                dataCollectionModal.variable = updateUserModel;
            } else {
                let userModel = { ...NewUserModel };
                userModel.name = dataCollectionModal.name;
                userModel.description = "";
                userModel.sysDataTypeId = 8;
                userModel.complexTypeValue = JSON.stringify(newComplexTypeValue);
                userModel.templateConnectionId = isTemplateCompany ? _.get(_.find(state?.connectionList, { uId: state?.connectionUId }), 'id') : null;
                dataCollectionModal.variable = userModel;
            }
        }

        if (dataCollectionModal?.templateCollectionId) {
            setIsCloudLoading(true);
            var allColumns = state?.collectionColumnSchema?.filter(i => i.isSelected);
            var columns = allColumns.map((i) => {
                var currentColl = dataCollectionModal.collectionColumns.filter(c => c.columnName == i.columnName);
                if(currentColl.length > 0){
                    const matchingFormat = findDisplayFormat(i.dataTypeName, state);

                    return {
                        collectionId: currentColl[0].collectionId,
                        columnName: currentColl[0].columnName,
                        dataTypeName: i.dataTypeName,
                        displayFormat: isNullOrEmpty(i.displayFormat) ? (matchingFormat?.displayFormat ?? AppEnum.DefaultDisplayFormat.General) : i.displayFormat,
                        dataType: matchingFormat?.sysDataTypeId ?? 6,
                        displayName: currentColl[0].displayName,
                        id: currentColl[0].id,
                        isDefault: currentColl[0].isDefault,
                        isSortable: currentColl[0].isSortable,
                        isFilterable: currentColl[0].isFilterable,
                        notQuerable: currentColl[0].notQuerable,
                        uId: currentColl[0].uId,
                        displayOrder: i.displayOrder ?? 0,
                    }
                }
                else{
                    const matchingFormat = findDisplayFormat(i.dataTypeName, state);

                    return {
                        ...i,
                        displayOrder: i.displayOrder ?? 0,
                        displayFormat: isNullOrEmpty(i.displayFormat) ? (matchingFormat?.displayFormat ?? AppEnum.DefaultDisplayFormat.General) : i.displayFormat,
                        dataType: matchingFormat?.sysDataTypeId ?? 6
                    };
                }
            })

            dataCollectionModal.collectionColumns = columns;
            dataCollectionModal.savedParameterValue = handleSavingCloudParams();
            dataCollectionModal.restDataTransformationScript = state?.cloudDataTransformationScript;
        }

        if (dataCollectionModal.collectionColumns.length > 0 && !dataCollectionModal?.templateCollectionId) {
            dataCollectionModal["collectionColumns"] = updateDisplayOrder(dataCollectionModal?.collectionColumns, dataCollectionModal?.sysConnectionTypeId, "columns");
        }

        if (dataCollectionModal.collectionParameters.length > 0) {
            dataCollectionModal["collectionParameters"] = updateDisplayOrder(dataCollectionModal?.collectionParameters, dataCollectionModal?.sysConnectionTypeId, "parameters");
        }


        if (state?.hasMergeCollections) {
            dataCollectionModal.restBody = state.restBody;
            dataCollectionModal.hasMergeCollections = true;
            dataCollectionModal.sysCollectionTypeId = 1; // need to discuss
        }

        if ((collectionUId && !isTemplateCollection) || (collectionUId && selectedConnection?.templateConnectionId)) {
            dataCollectionModal.uId = collectionUId;
            updateDataCollection(dataCollectionModal);
        }
        else {

            try {
                collectionService.saveDataCollection(dataCollectionModal)
                    .then((response) => {
                        setIsSavingCollection(false);
                        setIsCloudLoading(false);
                        if (response?.hasError) {
                            setErrorMessage(response?.errorMessage);
                            toast.error(response?.errorMessage);
                        }
                        else {
                            resetErrorMessages();
                            navigate('/data-collections');
                            toast.success("Collection added successfully.");
                        }
                    })
            } catch (error) {
                toast.error(error);
                setIsSavingCollection(false);
            }
        }
    }

    // updates the changes done in the collection details
    const updateDataCollection = (dataCollectionModal) => {

        if (isNullOrEmpty(dataCollectionModal.uId) && state?.templateConnectionId) {
            dataCollectionModal.uId = collectionUId;
        }

        try {
            collectionService.updateDataCollection(dataCollectionModal)
                .then((response) => {
                    setIsSavingCollection(false);
                    setIsCloudLoading(false);
                    if (response?.hasError) {
                        setErrorMessage(response?.errorMessage);
                        toast.error(response?.errorMessage);
                    }
                    else {
                        resetErrorMessages();
                        navigate('/data-collections');
                        toast.success("Collection updated successfully.");
                    }

                })
        } catch (error) {
            setIsSavingCollection(false);
            toast.error(error);
        }
    }

    // it sets the error message state 
    const setErrorMessage = (response) => {
        setState((prevState) => { return { ...prevState, testCollectionError: response?.errorMessage } });
    }

    const windowRef = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            if (openConnectionPopup) {
                getToken(selectedConnection, stateConnectionParameter, windowRef, setState,
                    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, null);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openConnectionPopup])

    const handleAuthorizeAgain = async () => {
        if ((selectedConnection?.connectionTypeId == AppEnum.SysConnnectionTypeId.GST) || (selectedConnection?.implimenterConnectionTypeId == AppEnum.SysConnnectionTypeId.GST)) {
            openOtpPopupAuthorization(setState, selectedConnection, searchOptionsParameters,  setIsAuthorizeAgain, null, state)
        }
        else {
            authorizeAgain(setState, selectedConnection, windowRef, setOpenConnectionPopup, setStateConnectionParameter);
        }
    }

    // returns the final data collection modal
    const getDataCollectionModal = (prevCollection) => {
        let dataCollectionModal = new DataCollectionModal();
        let collection;
        if (prevCollection) {
            collection = prevCollection;
        } else {
            collection = state;
        }

        dataCollectionModal.hasMergeCollections = collection.hasMergeCollections;
        dataCollectionModal.collectionUIdsToMerge = collection.collectionUIdsToMerge;
        dataCollectionModal.connectionUId = collection.connectionUId;
        dataCollectionModal.groupName = collection.groupName;
        dataCollectionModal.childGroupName = collection.childGroupName;
        dataCollectionModal.name = collection.name;
        dataCollectionModal.sysCollectionTypeId = collection.sysCollectionTypeId;
        dataCollectionModal.sysConnectionTypeId = collection.sysConnectionTypeId;
        dataCollectionModal.sysCollectionSubTypeId = collection.sysCollectionSubTypeId;
        dataCollectionModal.sourceName = collection.sourceName;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.sourceNamePart4 = collection.sourceNamePart4;
        dataCollectionModal.categoryUId = collection.categoryUId;
        dataCollectionModal.columnSchema = collection.columnSchema;
        dataCollectionModal.isCollectionAsDataSource = collection.isCollectionAsDataSource;
        dataCollectionModal.hasPrintableCollection = collection.hasPrintableCollection;
        dataCollectionModal.collectionParameters = collection.collectionParameters;
        dataCollectionModal.collectionFilterColumns = collection.collectionFilterColumns;
        dataCollectionModal.collectionColumns = collection.collectionColumns;
        dataCollectionModal.uId = collection.uId;
        dataCollectionModal.restRequestMethod = collection.restRequestMethod ? collection.restRequestMethod : state.restRequestMethod;
        dataCollectionModal.restBody = collection.restBody ? collection.restBody : state.restBody;
        dataCollectionModal.restSendAsCD = collection.restSendAsCD ? collection.restSendAsCD : state.restSendAsCD;
        dataCollectionModal.restDataTransformationScript = collection.restDataTransformationScript ? collection.restDataTransformationScript : state.restDataTransformationScript;
        dataCollectionModal.templateCollectionId = collection?.templateCollectionId;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.isFilterSupported = collection?.isFilterSupported;
        dataCollectionModal.isSortSupported = collection?.isSortSupported;
        dataCollectionModal.isPagingSupported = collection?.isPagingSupported;
        dataCollectionModal.areAllFieldsFilterable = collection?.areAllFieldsFilterable;
        dataCollectionModal.areAllFieldsSortable = collection?.areAllFieldsSortable;
        dataCollectionModal.columnApiEndpoint = collection?.columnApiEndpoint;
        dataCollectionModal.variableId = collection?.variableId;
        dataCollectionModal.variable = collection?.variable;
        dataCollectionModal.returnRawData = collection.returnRawData;
        dataCollectionModal.collectionUIdsToMerge = collection.collectionUIdsToMerge;
        dataCollectionModal.combineMergedParameters = collection.combineMergedParameters;
        dataCollectionModal.mergeScript = collection.mergeScript ? collection.mergeScript : state.mergeScript;
        dataCollectionModal.dataStylingScript = collection.dataStylingScript;
        dataCollectionModal.dataStylingScriptId = collection.dataStylingScriptId;
        dataCollectionModal.dataTransformationScriptId = collection.dataTransformationScriptId;
        dataCollectionModal.useTemplateConnection = collection.useTemplateConnection;
        dataCollectionModal.flattenLikeSql = collection?.flattenLikeSql || false;
        dataCollectionModal.excludedProperties = collection?.excludedProperties || "";
        return dataCollectionModal;
    }


    //updating collection active status
    const updateActiveStatus = (event) => {

        setActiveStatus(event.target.checked)
        setErrorMessage(null);

        const model = {
            uId: collectionUId,
            isActive: event.target.checked,
            fieldsToUpdate: ["IsActive"]
        };

        collectionService.updateDataCollection(model)
            .then((response) => {

                if (response?.hasError) {
                    setErrorMessage(response);
                    toast.error(response);
                }
                else {
                    resetErrorMessages();
                    toast.success("Collection updated successfully.");
                }
            })
    };

    // resets the error message states to null
    const resetErrorMessages = () => {
        setState((prevState) => {
            return {
                ...prevState, basicInfoError: null
                , columnSchemaError: null, testCollectionError: null, cloudCollectionError: null
            }
        })
    }

    const getCollectionObjectByType = () => {

        setErrorMessage(null);
        // setState((prevState) => { return { ...prevState, sourceNamePart2: "" } });
        setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });

        let connectionTypeName = selectedConnection?.connectionTypeName?.toLocaleLowerCase();
        collectionService.getCollectionObjectByType(connectionTypeName)
            .then((response) => {

                if (response?.data && response?.data.length > 0) {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: response.data } });
                }
                else {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });
                    setErrorMessage("No Data Present for this connection")
                }
            })
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        connectionService.getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                setSelectedConnection(res.data);
            }
        });
    }

    const getTestedCollection = (prevCollection) => {
        let collection = prevCollection ? prevCollection : state;

        let testDataCollectionModal = Object.assign({}, {
            connectionUId: collection.connectionUId,
            sysCollectionTypeId: collection.sysCollectionTypeId,
            sourceName: collection.sourceName,
            restRequestMethod: collection.restRequestMethod,
            restRequestIsAsync: true,
            restBody: collection.restBody,
            restSendAsCD: collection.restSendAsCD,
            collectionParameters: collection.collectionParameters,
            collectionColumns: collection.collectionColumnSchema,
            uId: collection.uId,
            returnFlatternData: collection.sysCollectionTypeId === 7 ? !(collection.returnRawData) : false,
            sourceNamePart4: collection.sourceNamePart4,
            restDataTransformationScript: collection?.restDataTransformationScript,
            returnRawData: collection.returnRawData,
            hasMergeCollections: collection.hasMergeCollections,
            collectionUIdsToMerge: collection.collectionUIdsToMerge,
            flattenLikeSql: collection?.flattenLikeSql || false,
            excludedProperties: collection?.excludedProperties || ""
        });

        testData(testDataCollectionModal, null, null, null, null, null, null, null, setState, setIsAuthorizeAgain, setIsShowHeader);
    }

    const handleSavingCloudParams = () => {
        let paramsToSave = searchOptionsParameters?.filter(i => i.enableSavingParameterValue);
        if (paramsToSave?.length > 0) {
            const result = paramsToSave.map(param => ({
                Id: param.id,
                Value: param.parameterValue,
            }));
            return JSON.stringify(result);
        }
    }

    // checks if all the basic info details are submitted
    const isBasicInfoSubmitted = useMemo(() => {
        return (state.name && state.groupName && state.connectionUId) &&
            (state.isCollectionAsDataSource
                ? (isDynamicListValues.DisplayColumn.trim().length > 0 &&(Array.isArray(isDynamicListValues?.ValueColumn) ?  isDynamicListValues.ValueColumn.length > 0 :  isDynamicListValues.ValueColumn.trim().length > 0) && isDynamicListValues.isDefaultValueCorrect) : true) &&
            (typeof state.basicInfoError === "string" && state.name !== initialCollectionName ? state.basicInfoError?.length === 0 : true);

    }, [state.name, state.groupName, state.connectionUId, state.isCollectionAsDataSource, isDynamicListValues.DisplayColumn, isDynamicListValues.ValueColumn, isDynamicListValues.isDefaultValueCorrect, state.basicInfoError, initialCollectionName]);

    // checks if all the collection type details are submitted
    const isCollectionTypeSubmitted = useMemo(() => {
        return (selectedConnection?.templateConnectionId) ? (state.sysCollectionTypeId) : true;
    }, [selectedConnection?.templateConnectionId, state.sysCollectionTypeId]);

    // checks if all the collection type details are submitted
    const isParameterSubmitted = useMemo(() => state.collectionParameters?.length > 0, [state.collectionParameters]);
    const isCollectionFilterColumnSubmitted = useMemo(() => state.collectionFilterColumns?.length > 0, [state.collectionFilterColumns]);

    // checks if any column and format is submitted
    const isColumnAndFormatSubmitted = useMemo(() => {
        return (state.collectionColumns?.length > 0) && isManualColumnValid;
    }, [state.collectionColumns, isManualColumnValid]);

    const isDataAfterFormatingStep = useMemo(() => {
        return isShowHeader
            ? state.collectionColumnSchema?.length > 0
            : state?.testedDataTransformedColumneKeys?.length > 0;
    }, [isShowHeader, state.collectionColumnSchema, state?.testedDataTransformedColumneKeys]);

    let isConnectionSelected = state.connectionUId;





    const handleStepDataChange = (requireTestCollection = true) => {
        if (!isNullOrEmpty(collectionUId)) {
            setIsStepDataChanged(true);
            if (requireTestCollection) {
                setIsTestedAfterSaveAppears(false);
            }
            else {
                setIsTestedAfterSaveAppears(true);
            }
        }
    };

    const updateDisplayOrder = (collectionColumns, sysDataTypeID, field) => {
        let displayOrderValue = 0;

        return collectionColumns?.map((colDef) => {
            if (isTemplateCompany && field === "columns" && sysDataTypeID == AppEnum?.SysConnnectionTypeId?.REST_Service) {
                if (colDef?.isDefault) {
                    displayOrderValue++;
                    return { ...colDef, displayOrder: displayOrderValue };
                }
                return { ...colDef, displayOrder: 0 };
            }
            else {
                displayOrderValue++;
                return { ...colDef, displayOrder: displayOrderValue };
            }
        });
    };

    const commonRestSteps = [
        {
            label: CollectionStepLabelConstant.Test_Data,
            description: (
                <TestDataController
                    state={state}
                    setState={setState}
                    getDataCollectionModal={getDataCollectionModal}
                    resetErrorMessages={resetErrorMessages}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setIsAuthorizeAgain={setIsAuthorizeAgain}
                    selectedConnection={selectedConnection}
                    getColumnSchema={getColumnSchema}
                    setIsTestedData={setIsTestedData}
                    isChangedDataParams={isChangedDataParams}
                    setIsChangedDataParams={setIsChangedDataParams}
                    setIsShowHeader={setIsShowHeader}
                    showSaveCollection={isStepDataChanged}
                    setIsTestedAfterSaveAppears={setIsTestedAfterSaveAppears}
                    preDefinedScripts={preDefinedScripts}
                    setIsDataModified={setIsDataModified}
                />
            ),
            isTestedData: isTestedData,
            optionalComponent: { testedDataList: state.testedDataList, testedDataColumnKeys: state.testedDataColumnKeys, returnRawData: state.returnRawData },
            disabled: isTestedData ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_Transformation_Script,
            description: (
                <DataTransformationScriptController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataFormationStep: true,
            optionalComponent: { restDataTransformationScript: state.restDataTransformationScript },
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Data_After_Transformation,
            description: (
                <DataAfterTransformationController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataAfterFormatingStep: isDataAfterFormatingStep,
            optionalComponent: {
                testedDataTransformedList: state.testedDataTransformedList,
                testedDataTransformedColumneKeys: state.testedDataTransformedColumneKeys,
                columnSchemaList: state.columnSchemaList
            },
            disabled: isDataAfterFormatingStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_Columns_And_Formatting,
            description: (
                <SelectColumnsController
                    state={state}
                    prevStepRef={prevStepRef}
                    setState={setState}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    getDisplayFormatList={getDisplayFormatList}
                    collectionUId={collectionUId}
                    isRest={state?.sysCollectionTypeId === 7}
                    setIsManualColumnValid={setIsManualColumnValid}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isColumnAndFormatSubmitted: isColumnAndFormatSubmitted,
            optionalComponent: { collectionColumns: state.columnSchemaList, isTemplateCompany : isTemplateCompany && state?.sysCollectionTypeId === AppEnum.SysCollectionTypeId.REST},
            disabled: isColumnAndFormatSubmitted ? false : true,
        },
        ...(isTemplateCompany ? [
            {
                label: CollectionStepLabelConstant.Collection_Column_Filters,
                description: (
                    <CollectionFilterColumnController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        dataCollectionModal={dataCollectionModal}
                        setIsChangedDataParams={setIsChangedDataParams}
                        collectionFilterColumnState={collectionFilterColumnState}
                        setCollectionFilterColumnState={setCollectionFilterColumnState}
                    />
                ),
                isCollectionFilterColumnSubmitted: isCollectionFilterColumnSubmitted,
                optionalComponent: { collectionFilterColumns: state?.collectionFilterColumns, showSendAs: state.sysCollectionTypeId == 7 },
                disabled: false,
            },
        ] : []),

        {
            label: CollectionStepLabelConstant.Basic_Information,
            description: (
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    initialCollectionName={initialCollectionName}
                    isDynamicListValues={isDynamicListValues}
                    setIsDynamicListValues={setIsDynamicListValues}
                    isVariableUsedInCollection={isVariableUsedInCollection}
                    isRest={state?.sysCollectionTypeId === 7}
                    isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                    setShowTestCollectionPopup={setShowTestCollectionPopup}
                    showSaveCollection={isStepDataChanged}
                    isTestedData={isTestedData}
                />
            ),
            isBasicInfo: true,
            optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
            isCollectionType: state?.name ? false : true,
            isCollectionAsDataSource: state.isCollectionAsDataSource,
            hasMergeCollections: state?.hasMergeCollections ? true : false,
            disabled: isBasicInfoSubmitted ? false : true,
        },
    ]

    const restSteps = [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            optionalComponent: { labels: selectedConnectionSubmited, },
            isCollectionType: true,
            // isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={selectedConnection.connectionTypeId == 2 || selectedConnection?.baseConnectionTypeId == 2}
                    setIsChangedDataParams={setIsChangedDataParams}
                    isTestedData={isTestedData}
                    isTemplateCompany={isTemplateCompany}
                    setIsTestedData={setIsTestedData}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            optionalComponent: { labels: submittedCollectionType, },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Send_Body_As,
            description: (
                <SendBodyAsController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isShowStep={state.restRequestMethod == "GET"}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isSendBodyAs: true,
            optionalComponent: { collectionParameters: state.collectionParameters, },
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Parameters,
            description: (
                <CollectionParametersController
                    isTemplateCompany={isTemplateCompany}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setParametersState={setParametersState}
                    parametersState={parametersState}
                    dataCollectionModal={dataCollectionModal}
                    extractedParamters={extractedParamters}
                    setExtractedParamters={setExtractedParamters}
                    isTestedData={isTestedData}
                    setIsChangedDataParams={setIsChangedDataParams}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isParameterSubmitted: isParameterSubmitted,
            optionalComponent: { collectionParameters: state.collectionParameters, showSendAs: state.sysCollectionTypeId == 7 },
            disabled: false,
        },

        ...commonRestSteps
    ];

    if (isShowHeader) {
        restSteps.splice(7, 1);
    }

    const steps = [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            optionalComponent: { labels: selectedConnectionSubmited, },
            isCollectionType: true,
            // isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={selectedConnection.connectionTypeId == 2 || selectedConnection?.baseConnectionTypeId == 2}
                    setIsChangedDataParams={setIsChangedDataParams}
                    isTestedData={isTestedData}
                    isTemplateCompany={isTemplateCompany}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setIsTestedData={setIsTestedData}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            optionalComponent: { labels: submittedCollectionType, },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Parameters,
            description: (
                <CollectionParametersController
                    isTemplateCompany={isTemplateCompany}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setParametersState={setParametersState}
                    parametersState={parametersState}
                    dataCollectionModal={dataCollectionModal}
                    extractedParamters={extractedParamters}
                    setExtractedParamters={setExtractedParamters}
                    isTestedData={isTestedData}
                    setIsChangedDataParams={setIsChangedDataParams}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isParameterSubmitted: isParameterSubmitted,
            optionalComponent: { collectionParameters: state.collectionParameters, },
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Test_Data,
            description: (
                <TestDataController
                    state={state}
                    setState={setState}
                    getDataCollectionModal={getDataCollectionModal}
                    resetErrorMessages={resetErrorMessages}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setIsAuthorizeAgain={setIsAuthorizeAgain}
                    selectedConnection={selectedConnection}
                    getColumnSchema={getColumnSchema}
                    setIsTestedData={setIsTestedData}
                    isChangedDataParams={isChangedDataParams}
                    setIsChangedDataParams={setIsChangedDataParams}
                    isShowHeader={isShowHeader}
                    isTestedData={isTestedData}
                    setIsShowHeader={setIsShowHeader}
                    showSaveCollection={isStepDataChanged}
                    setIsTestedAfterSaveAppears={setIsTestedAfterSaveAppears}
                    preDefinedScripts={preDefinedScripts}
                    setIsDataModified={setIsDataModified}
                />
            ),
            isTestedData: isTestedData,
            optionalComponent: { testedDataList: state.testedDataList, testedDataColumnKeys: state.testedDataColumnKeys },
            disabled: isTestedData ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_Transformation_Script,
            description: (
                <DataTransformationScriptController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataFormationStep: true,
            optionalComponent: { restDataTransformationScript: state.restDataTransformationScript },
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Data_After_Transformation,
            description: (
                <DataAfterTransformationController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isShowHeader={isShowHeader}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataAfterFormatingStep: isDataAfterFormatingStep,
            optionalComponent: {
                testedDataTransformedList: state.testedDataTransformedList,
                testedDataTransformedColumneKeys: state.testedDataTransformedColumneKeys,
                columnSchemaList: state.columnSchemaList, collectionColumnSchema: state.collectionColumnSchema
            },
            disabled: isDataAfterFormatingStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_Columns_And_Formatting,
            description: (
                <SelectColumnsController
                    state={state}
                    prevStepRef={prevStepRef}
                    setState={setState}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    getDisplayFormatList={getDisplayFormatList}
                    collectionUId={collectionUId}
                    isRest={state?.sysCollectionTypeId === 7}
                    setIsManualColumnValid={setIsManualColumnValid}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isColumnAndFormatSubmitted: isColumnAndFormatSubmitted,
            optionalComponent: { collectionColumns: state.columnSchemaList, isTemplateCompany : isTemplateCompany && state?.sysCollectionTypeId === AppEnum.SysCollectionTypeId.REST},
            disabled: isColumnAndFormatSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Basic_Information,
            description: (
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    initialCollectionName={initialCollectionName}
                    isDynamicListValues={isDynamicListValues}
                    setIsDynamicListValues={setIsDynamicListValues}
                    isVariableUsedInCollection={isVariableUsedInCollection}
                    isRest={state?.sysCollectionTypeId === 7}
                    isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                    setShowTestCollectionPopup={setShowTestCollectionPopup}
                    showSaveCollection={isStepDataChanged}
                    isTestedData={isTestedData}
                />
            ),
            isBasicInfo: true,
            optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
            isCollectionType: state?.name ? false : true,
            isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isBasicInfoSubmitted ? false : true,
        }
    ];

    const cloudConnectionSteps = [

        state?.uId ? (
            {
                label: CollectionStepLabelConstant.Basic_Information,
                description: (
                    <BasicInformationController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        getCollectionTypeList={getCollectionTypeList}
                        setSelectedConnection={setSelectedConnection}
                        initialCollectionName={initialCollectionName}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        isCloudStep={true}
                        selectedConnection={selectedConnection}
                        isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                        setShowTestCollectionPopup={setShowTestCollectionPopup}
                        showSaveCollection={isStepDataChanged}
                    />
                ),
                isBasicInfo: true,
                optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
                isCollectionType: state?.name ? false : true,
                isCollectionAsDataSource: state.isCollectionAsDataSource,
                disabled: isBasicInfoSubmitted ? false : true,
            }
        ) :
            ({
                label: CollectionStepLabelConstant.Select_Connection,
                description: (
                    <SelectConnectionController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        getCollectionTypeList={getCollectionTypeList}
                        setSelectedConnection={setSelectedConnection}
                        setParametersState={setParametersState}
                    />
                ),
                isSelectConnection: true,
                optionalComponent: { labels: selectedConnectionSubmited, },
                isCollectionType: true,
                // isCollectionAsDataSource: state.isCollectionAsDataSource,
                disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
            }),
        {
            label: CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={false}
                    isTemplateCompany={isTemplateCompany}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setIsTestedData={setIsTestedData}
                />
            ),
            optionalComponent: { labels: submittedCollectionType },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_An_Object,
            description: (
                <SelectObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    getCollectionObjectByType={getCollectionObjectByType}
                />
            ),
            optionalComponent: { labels: submittedCloudObject, },
            isCollectionType: true,
            disabled: state.templateCollectionId ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_View_Columns,
            description: (
                <ColumnSelectionController
                    maunalColumns={maunalColumns}
                    columnOverriden={columnOverriden}
                    setColumnOverriden={setColumnOverriden}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setSelectedConnection={setSelectedConnection}
                    selectedConnection={selectedConnection}
                    saveCollection={addNewDataCollection}
                    isBasicInfoSubmitted={isBasicInfoSubmitted}
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    isUpdatingCollection={isNullOrEmpty(collectionUId) ? false : true}
                    isLoading={isCloudLoading}
                    setIsLoading={setIsCloudLoading}
                    getCollectionObjectByType={getCollectionObjectByType}
                    prevCloudFilterationData={prevCloudFilterationData}
                    isCloudFilterationData={isCloudFilterationData}
                    setIsCloudFilterationData={setIsCloudFilterationData}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setSearchOptionsParameters={setSearchOptionsParameters}
                    isSearchOptionsAvailable={isSearchOptionsAvailable}
                    searchOptionsParameters={searchOptionsParameters}
                    setIsSearchOptionsAvailable={setIsSearchOptionsAvailable}
                    prevCloudTransformationScripts={prevCloudTransformationScripts}
                />
            )
        }
    ];

    const mergeQuerySteps = useMemo(() => {
        return MergeCollectionSteps({
            resetErrorMessages,
            state,
            setState,
            prevStepRef,
            getCollectionTypeList,
            setSelectedConnection,
            setParametersState,
            selectedConnectionSubmitted: selectedConnectionSubmited,
            selectedConnection,
            isConnectionSelected,
            isTemplateCompany, activeStep, isTestingConnection: state.isTestingConnection, commonRestSteps, setMergeCollectionState, mergeCollectionState, handleStepDataChange,
            preDefinedScripts, setPreDefinedScripts            
        });
    }, [state, prevStepRef, selectedConnectionSubmited, isConnectionSelected, selectedConnection, isTemplateCompany, mergeCollectionState, activeStep, commonRestSteps])



    const handleSaveChangesWithPopupedButton = () => {

        const testCollectionStep = (state?.sysCollectionTypeId === 7) ? 4 : 3
        if (activeStep < testCollectionStep) {
            setShowTestCollectionPopup(true);
        }
        else {
            if (activeStep >= testCollectionStep) {
                if (!isTestedAfterSaveAppears) {
                    setShowTestCollectionPopup(true);
                    return;
                }
            }
            if (showTestCollectionPopup) {
                setShowTestCollectionPopup(false);
            }
            addNewDataCollection();
        }
    }


    const backTwoStep = useMemo(() => {

        return (selectedConnection?.templateConnectionId) ?
            ((selectedConnection?.templateConnectionId && (state.collectionTypeList.length <= 0 || state.collectionTypeList.length == 1))) :
            (state.restRequestMethod == "GET" && activeStep == 3 && state?.sysCollectionTypeId === 7)

    }, [selectedConnection?.templateConnectionId, state.collectionTypeList, state.restRequestMethod, activeStep, state?.sysCollectionTypeId])

    const isRestSteps = useMemo(() => {
        return (state?.sysCollectionTypeId === 7) ? true : false
    }, [state?.sysCollectionTypeId])

    const selectedConnectionSteps = (state.hasMergeCollections ? mergeQuerySteps : (selectedConnection?.length == 0 && collectionUId) ? [] : (selectedConnection?.templateConnectionId) ? cloudConnectionSteps :
        (state?.sysCollectionTypeId === 7) ? restSteps : steps);


    return (
        <>
            <ASStepper
                sysCollectionTypeId={state?.sysCollectionTypeId}
                backTwoStep={backTwoStep}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={selectedConnectionSteps}
                uId={isTemplateCollection ? null : collectionUId}
                isTestingConnection={state.isTestingConnection}
                save={addNewDataCollection}
                setErrorMessage={resetErrorMessages}
                activeStatus={activeStatus}
                updateActiveStatus={updateActiveStatus}
                isTemplateCollection={isTemplateCollection}
                isSavingCollection={isSavingCollection}
                stepperViewFromConnection={stepperViewFromConnection}
                variableList={state.variableList}
                isShowHeader={isShowHeader}
                isCloudSteps={selectedConnection?.templateConnectionId}
                collectionUId={collectionUId}
                isCollectionSteps={true}
                showSaveCollection={isStepDataChanged}
                handleSaveChangesWithPopupedButton={handleSaveChangesWithPopupedButton}
                isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                setShowTestCollectionPopup={setShowTestCollectionPopup}
                isRestSteps={isRestSteps}
                isTestedData={isTestedData}
                isMergeCollectionStep={state.hasMergeCollections}
                isDataModified={isDataModified}
            />

            <MuiDialog
                isShowPopUp={showTestCollectionPopup}
                primaryButtonAction={() => {
                    setActiveStep((state?.sysCollectionTypeId === 7) ? 4 : 3)
                    setShowTestCollectionPopup(false)
                }}
                primaryButtonTitle="Go to Test Collection"
                titleDescription="Please test your collection first."
            />
        </>
    );
}
